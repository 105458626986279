<div class="row">
  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
    <div class="form-group">
      <label>Template Name <span class="text-danger">*</span></label>
      <input type="text" class="form-control form-control-sm" placeholder="Template Name" (keypress)="maxLength($event)" [(ngModel)]="templateName">
    </div>
  </div>
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mt-4">
    <div class="d-flex align-items-center justify-content-end">
      <label class="pr-2">Self</label>
      <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        checked="checked"
                        name="select"
                        maxlength="50"
                        [(ngModel)]="self"
                      />
                      <span></span>
                    </label>
                  </span>
      <label class="pr-2">My Department</label>
    </div>
  </div>
<!--Description Editor-->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="form-group">
      <label>Description <span class="text-danger">*</span></label>
      <textarea
        class="textareas form-control"
        rows="2"
        id="description"
        [(ngModel)]="description"
      ></textarea>
    </div>
  </div>

<!--  Submit Button-->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right">
    <button type="button" class="btn btn-sm btn-primary" (click)="submit()" [disabled]="isSubmit">Submit</button>
  </div>

<!--  table listing-->
  <div class="table-responsive" style="max-height: 250px; overflow-y: auto">
    <table class="table table-head-custom table-vertical-center" *ngIf="opdTemplateData.length">
      <thead>
      <tr>
        <th style="width: 10%">Code</th>
        <th style="width: 25%">Name</th>
        <th style="width: 20%">User</th>
        <th style="width: 25%">Department</th>
        <th style="width: 10%">Status</th>
        <th class="text-center" style="width: 10%">Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let list of opdTemplateData; let i = index">
        <tr style="border-top: none !important">
          <td>{{list?.code}}</td>
          <td>{{ list?.name }}</td>
          <td>{{list?.employee_name}}</td>
          <td>{{list?.department_name}}</td>
          <td>
            <span class="label label-lg label-light-primary label-inline" *ngIf="list?.status">Active</span>
            <span class="label label-lg label-light-danger label-inline" *ngIf="!list?.status">Inactive</span>
          </td>
          <td class="text-center">
            <button
              type="button"
              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
              (click)="edit(list)"
            >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                        ></path>
                        <path
                          d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
            </button>
          </td>
        </tr>
        <tr *ngIf="list.description">
          <td colspan="11" class="font-weight-bold" style="border-top: none !important">
            <div class="d-flex">
              <div>Description: </div>
              <div class="ml-3">  <app-summernotes
                [id]="'description' + i"
                [htmlData]="list?.description">
              </app-summernotes>
              </div>
            </div>
          </td>
        </tr>

      </ng-container>
      </tbody>
    </table>
  </div>
</div>


<!--Modal-->

<div
  class="modal fade"
  id="opdPresModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title">Update Template</h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeTemplateModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="prescriptionForm" (ngSubmit)="onsubmit()">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Code  <span class="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Code"
                  class="form-control form-control-sm"
                  formControlName="code"
                  [ngClass]="{'is-invalid': isAdd && prescriptionForm.controls.code.errors}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Name  <span class="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Name"
                  class="form-control form-control-sm"
                  formControlName="name"
                  [ngClass]="{'is-invalid': isAdd && prescriptionForm.controls.name.errors}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="form-group"
                   [ngClass]="{'is-invalid': isAdd && prescriptionForm.controls.department.errors}"
              >
                <label>Department <span class="text-danger">*</span></label>
                <select
                  name="department"
                  id="department"
                  class="form-control form-control-sm selectPickerDepartment bootstrap-select-container"
                  data-container="body"
                  data-none-selected-text="Select Department"
                  data-live-search="true"
                  formControlName="department"
                >
                  <option [value]="''">Select Department</option>
                  <ng-container *ngFor="let dep of depart">
                    <option [value]="dep.id">{{ dep.title }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="form-group"
                   [ngClass]="{'is-invalid': isAdd && prescriptionForm.controls.employee.errors}"
              >
                <label>User <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  data-live-search="true"
                  data-container="body"
                  id="employee"
                  data-none-selected-text="Select User"
                  name="department"
                  formControlName="employee"
                >
                  <option value="" [disabled]="true">Select User</option>
                  <ng-container *ngFor="let emp of employees">
                    <option [value]="emp.id">
                      {{ emp?.employee_file_no }} - {{ emp?.first_name }}
                      {{ emp?.middle_name || "" }} {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Type <span class="text-danger">*</span></label>
                <select class="form-control form-control-sm" formControlName="type"
                        [ngClass]="{'is-invalid': isAdd && prescriptionForm.controls.type.errors}">
                  <option value="">Select</option>
                  <option value="presenting_complaint">Presenting Complaint</option>
                  <option value="drug_history">Drug History</option>
                  <option value="examination">Examination</option>
                  <option value="plan">Plans</option>
                  <option value="treatment">Treatment</option>
                  <option value="lab">Labs</option>
                  <option value="history_of_hospitalization">History of Hospitalization</option>
                  <option value="follow_up_instructions">Follow Up Instructions</option>
                  <option value="past_dental_history">Past Dental History</option>
                  <option value="history_of_presenting_complaint">History of Presenting Complaints</option>
                  <option value="gynae_presenting_complaint">Gynae Presenting Complaint</option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Status</label>
                <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        checked="checked"
                        name="select"
                        formControlName="status"
                      />
                      <span></span>
                    </label>
                  </span>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <label>Description</label>
                <textarea
                  class="textareas form-control"
                  rows="2"
                  id="templateDescription"
                  formControlName="description"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"  class="btn btn-secondary btn-sm"
                  (click)="closeTemplateModal()">
            Close
          </button>
          <button type="submit" class="btn btn-sm btn-primary" [disabled]="disableBtn">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
