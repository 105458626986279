<div>
  <!--begin::Card-->
<!--  <div class="card mb-4" *ngIf="isAdd === true">-->
<!--    <div class="card-body user-card-body">-->
<!--      <h4 class="mb-8">Operation Request</h4>-->

<!--      <form-->
<!--        [formGroup]="operationRequestForm"-->
<!--        (ngSubmit)="onSubmitoperationRequest()"-->
<!--      >-->
<!--        <div class="row mb-6">-->
<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group selectOptionBottom">-->
<!--              <label> Advised by </label>-->
<!--              <div-->
<!--                [ngClass]="{-->
<!--                  'is-invalid':-->
<!--                    isSubmitoperationRequest && c.advisory_doctor.errors-->
<!--                }"-->
<!--              >-->
<!--                <select-->
<!--                  class="form-control form-control-sm"-->
<!--                  id="otAdviceBy"-->
<!--                  data-live-search="true"-->
<!--                  formControlName="advisory_doctor"-->
<!--                  (change)="fetchDoctorDepart($event.target.value)"-->
<!--                >-->
<!--                  <option value="">select</option>-->
<!--                  <option *ngFor="let doc of oRDoctors" [value]="doc.id">-->
<!--                    {{ doc?.first_name }} {{ doc?.last_name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group selectOptionBottom">-->
<!--              <label> Department </label>-->
<!--              <div-->
<!--                [ngClass]="{-->
<!--                  'is-invalid': isSubmitoperationRequest && c.department.errors-->
<!--                }"-->
<!--              >-->
<!--                <select-->
<!--                  class="form-control form-control-sm"-->
<!--                  id="otDepartment"-->
<!--                  data-live-search="true"-->
<!--                  formControlName="department"-->
<!--                  (change)="fetchDepartDoctors($event.target.value)"-->
<!--                >-->
<!--                  <option value="">select</option>-->
<!--                  <option *ngFor="let dep of department" [value]="dep.id">-->
<!--                    {{ dep.title }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group selectOptionBottom">-->
<!--              <label> Operation Name </label>-->
<!--              <div-->
<!--                [ngClass]="{-->
<!--                  'is-invalid': isSubmitoperationRequest && c.ot_service.errors-->
<!--                }"-->
<!--              >-->
<!--                <select-->
<!--                  class="form-control form-control-sm"-->
<!--                  id="otServiceName"-->
<!--                  data-live-search="true"-->
<!--                  formControlName="ot_service"-->
<!--                >-->
<!--                  <option value="">select</option>-->
<!--                  <option-->
<!--                    *ngFor="let service of otServices"-->
<!--                    [value]="service.id"-->
<!--                  >-->
<!--                    {{ service.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group selectOptionBottom">-->
<!--              <label> Operation Room </label>-->
<!--              <div>-->
<!--                <select-->
<!--                  class="form-control form-control-sm"-->
<!--                  id=""-->
<!--                  data-live-search="true"-->
<!--                  formControlName="operation_room"-->
<!--                >-->
<!--                  <option value="">select</option>-->
<!--                  <option [value]="or.id" *ngFor="let or of operationRooms">-->
<!--                    {{ or.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group">-->
<!--              <label> Anesthesia Type </label>-->
<!--              <select-->
<!--                class="form-control form-control-sm"-->
<!--                data-live-search="true"-->
<!--                formControlName="anesthesia_type_id"-->
<!--                [ngClass]="{-->
<!--                  'is-invalid':-->
<!--                    isSubmitoperationRequest && c.anesthesia_type_id.errors-->
<!--                }"-->
<!--              >-->
<!--                <option value="">select</option>-->
<!--                <option-->
<!--                  *ngFor="let anesthesia of anesthesiaTypes"-->
<!--                  [value]="anesthesia.id"-->
<!--                >-->
<!--                  {{ anesthesia.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group">-->
<!--              <label>Suggested Date</label>-->
<!--              <input-->
<!--                type="date"-->
<!--                class="form-control form-control-sm"-->
<!--                min="{{ currentDate | date: 'yyyy-MM-dd' }}"-->
<!--                formControlName="suggested_date"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group">-->
<!--              <label>Suggested Time</label>-->
<!--              <input-->
<!--                type="time"-->
<!--                class="form-control form-control-sm"-->
<!--                formControlName="suggested_time"-->
<!--                [ngClass]="{-->
<!--                  'is-invalid':-->
<!--                    isSubmitoperationRequest && c.suggested_time.errors-->
<!--                }"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group">-->
<!--              <label>Estimated Duration</label>-->

<!--              <div style="display: flex !important">-->
<!--                <div class="col-6 pl-0">-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    class="form-control form-control-sm"-->
<!--                    formControlName="est_dur_value"-->
<!--                    [ngClass]="{-->
<!--                      'is-invalid':-->
<!--                        isSubmitoperationRequest && c.est_dur_value.errors-->
<!--                    }"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="col-6 pr-0">-->
<!--                  <select-->
<!--                    class="form-control form-control-sm"-->
<!--                    formControlName="est_dur_type_id"-->
<!--                    [ngClass]="{-->
<!--                      'is-invalid':-->
<!--                        isSubmitoperationRequest && c.est_dur_type_id.errors-->
<!--                    }"-->
<!--                  >-->
<!--                    <option value="">Select</option>-->
<!--                    <option-->
<!--                      *ngFor="let duration of durations"-->
<!--                      [value]="duration.id"-->
<!--                    >-->
<!--                      {{ duration.name }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-lg-3 col-md-3 col-sm-4 col-12">-->
<!--            <div class="form-group">-->
<!--              <label>Urgency</label>-->
<!--              <select-->
<!--                class="form-control form-control-sm"-->
<!--                formControlName="urgency_id"-->
<!--                [ngClass]="{-->
<!--                  'is-invalid': isSubmitoperationRequest && c.urgency_id.errors-->
<!--                }"-->
<!--              >-->
<!--                <option value="">Select</option>-->
<!--                <option *ngFor="let urgency of urgency" [value]="urgency.id">-->
<!--                  {{ urgency.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-8">-->
<!--            <div class="form-group">-->
<!--              <label>Comments</label>-->
<!--              <input-->
<!--                class="form-control form-control-sm"-->
<!--                formControlName="comments"-->
<!--                [ngClass]="{-->
<!--                  'is-invalid': isSubmitoperationRequest && c.comments.errors-->
<!--                }"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row m-0">-->
<!--          <div class="text-left">-->
<!--            <div class="otherComponent">-->
<!--              <div>-->
<!--                <app-upload-images-->
<!--                  [documentType]="'document'"-->
<!--                  [isUploadImage]="true"-->
<!--                  (imageData)="sendImages($event)"-->
<!--                ></app-upload-images>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;            <span class="btn btn-primary btn-sm" (click)="uploadDocument()">Upload Document</span>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="col text-right">-->
<!--            <button-->
<!--              type="button"-->
<!--              (click)="resetOperationRequestForm()"-->
<!--              class="btn btn-primary btn-sm"-->
<!--            >-->
<!--              Clear-->
<!--            </button>-->
<!--            <button type="submit" class="btn btn-primary btn-sm ml-2">-->
<!--              Submit-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
  <div class="card mb-4" *ngIf="isAdd === true">
    <div class="card-body p-4">
      <div class="d-flex justify-content-between">
        <div class="mt-2"><h5>Operation Request</h5></div>
      <div>
        <button class="btn btn-sm btn-primary" (click)="openRequestModal()">Create Request</button>
      </div>
      </div>
    </div>
  </div>
  <div class="card mb-4" *ngIf="!isAdd || allOperationsRequests.length > 0">
    <div class="card-body" style="padding: 1.5rem 1rem 0rem 1rem !important">
      <ng-container *ngFor="let operations of allOperationsRequests">
        <div class="row m-0 mb-3">
          <div class="col-md-2 col-sm-2 col-6">
            <div class="font-weight-bold">Procedure Name:</div>
            <div class="font-weight-bold">Department:</div>
            <div
              *ngIf="operations?.anesthesia_type.name"
              class="font-weight-bold"
            >
              Anesthesia Type:
            </div>
            <div class="font-weight-bold">Advised By:</div>
            <div *ngIf="operations?.paediation" class="font-weight-bold">Paediatrician:</div>
          </div>
          <div class="col-md-2 col-sm-2 col-6 pl-0">
            <div>{{ " " + operations?.ot_service?.name }}</div>
            <div>{{ " " + operations?.department?.title }}</div>
            <div *ngIf="operations?.anesthesia_type.name">
              {{ " " + operations?.anesthesia_type?.name }}
            </div>
            <div>{{ " " + operations?.advisory_doctor?.full_name }}</div>
            <div *ngIf="operations?.paediation">{{ " " + operations?.paediation}}</div>
          </div>
          <div class="col-md-2 col-sm-2 col-6">
            <div class="font-weight-bold">
              <span *ngIf="operations?.status_type === 'requested'"
                >Suggested Date:</span
              >
              <span *ngIf="operations?.status_type === 'approved'"
                >Approved Date:</span
              >
            </div>
            <div class="font-weight-bold">
              <span *ngIf="operations?.status_type === 'requested'"
                >Suggested Time:</span
              >
              <span *ngIf="operations?.status_type === 'approved'"
                >Approved Time:</span
              >
            </div>
            <div *ngIf="operations?.urgency?.name" class="font-weight-bold">
              Urgency:
            </div>
            <div class="font-weight-bold">Status:</div>
            <div *ngIf="operations?.operation_room_name" class="font-weight-bold">Operation Room:</div>
          </div>
          <div class="col-md-2 col-sm-2 col-6 pl-0">
            <div>
              <span *ngIf="operations?.status_type === 'requested'">{{
                " " + operations?.suggested_date | dateFormat
              }}</span>
              <span *ngIf="operations?.status_type === 'approved'">{{
                " " + operations?.approved_date | dateFormat
              }}</span>
            </div>
            <div>
              <span *ngIf="operations?.status_type === 'requested'">{{
                " " + operations?.suggested_time | timeFormat
              }}</span>
              <span *ngIf="operations?.status_type === 'approved'">{{
                " " + operations?.approved_time | timeFormat
              }}</span>
            </div>
            <div *ngIf="operations?.urgency?.name">
              {{ " " + operations?.urgency?.name }}
            </div>
            <div>{{ " " + operations?.status_type | titlecase }}</div>
            <div *ngIf="operations?.operation_room_name">{{ " " + operations?.operation_room_name }}</div>
          </div>
          <div class="col-md-2 col-sm-2 col-6 pl-0">
            <div *ngIf="operations?.screening" class="font-weight-bold">
              Hep. B & Hep. C Screening:
            </div>
            <div *ngIf="operations?.blood_product" class="font-weight-bold">Blood & Blood Products:</div>
            <div *ngIf="operations?.number_of_bags" class="font-weight-bold">
              No. of Bags Required:
            </div>
            <div *ngIf="operations?.case_type" class="font-weight-bold">Case Type:</div>
          </div>
          <div class="col-md-2 col-sm-2 col-6 pl-0">
            <div *ngIf="operations?.screening">{{operations?.screening}}</div>
            <div *ngIf="operations?.blood_product">{{operations?.blood_product}}</div>
            <div *ngIf="operations?.number_of_bags">{{operations?.number_of_bags}}</div>
            <div *ngIf="operations?.case_type">
              <span *ngIf="operations?.case_type === 'dirty_case'">Dirty</span>
              <span *ngIf="operations?.case_type === 'clean_case'">Clean</span>
            </div>
          </div>
          <div *ngIf="operations?.remarks" class="col-12 mt-3">
            <div><span class="font-weight-bold">Remarks: </span>{{ operations?.remarks }}</div>
          </div>
          <div class="col-md-3 col-sm-3 col-6" *ngIf="operations?.comments">
            <div class="font-weight-bold">Comments:</div>
          </div>
          <div
            class="col-md-9 col-sm-9 col-6 pl-0"
            *ngIf="operations?.comments"
          >
            <div>{{ " " + operations?.comments }}</div>
          </div>
          <div class="col-12 mt-3 text-right">
            <button
              class="btn btn-primary btn-sm"
              (click)="printPatientoperationRequest(operations.id)"
            >
              Print
            </button>
            <button
              *ngIf="
                operations?.approved === false &&
                operations?.status_type !== 'cancelled' &&
                isAdd === true
              "
              class="btn btn-primary btn-sm ml-2"
              (click)="cancelPatientoperationRequest(operations.id)"
            >
              Cancel Request
            </button>
          </div>
        </div>

        <hr *ngIf="allOperationsRequests.length > 1" />
      </ng-container>
    </div>
  </div>
  <!--end::Card-->
</div>

<!--cancel Modal-->
<div class="example-preview">
  <div>
    <ng-template #cancelOperationRequest let-c="close" let-d="dismiss">
      <div class="">
        <form>
          <div class="modal-header p-3">
            <h4 class="modal-title">Confirmation</h4>
            <a
              type="button"
              class="close"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div>Are you sure you want to cancel the request?<br /></div>
          </div>
          <div class="modal-footer p-3">
            <a
              type="button"
              class="btn btn-sm btn-primary"
              (click)="c('Close click')"
              >Close</a
            >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              (submit)="cancelRequest()"
              (click)="cancelRequest()"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>


<div class="example-preview">
  <div>
    <ng-template #content5 let-c="close" let-d="dismiss">
      <div class="operation-request-modal">
        <form
          [formGroup]="operationRequestForm"
          (ngSubmit)="onSubmitOperationRequest()"
        >
          <div class="modal-header">
            <h4 class="modal-title">Generate Request</h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="d('Cross click'); resetOperationRequestForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">

            <div class="d-flex justify-content-around" *ngIf="patient">
              <div class="symbol symbol-60 symbol-light-success mr-5">
                <span class="symbol-label symbol symbol-60">
                  <img
                    src="assets/media/svg/avatars/009-boy-4.svg"
                    class="align-self-end"
                    alt=""
                  />
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                <a class="text-dark mb-1 font-size-lg"
                ><span>{{ patient?.title | titlecase }}</span>
                  {{  patient?.first_name + " " + patient?.last_name }}
                </a>
                <div class="d-inline">
                  <span class="text-muted mr-4">{{
                      patient?.gender
                    }}</span>
                  <span class="text-muted mr-4">{{
                      patient?.age
                    }}</span>
                </div>
                <div class="d-inline">
                  <span class="text-muted mr-4">{{
                      patient?.mrn
                    }}</span>
                  <span class="text-muted mr-4">{{
                      patient?.phone1
                    }}</span>
                </div>
              </div>
            </div>

            <div class="row mt-5 my-3">
              <div class="col-md-4 col-sm-6 col-12 p-l-0">
                <div class="form-group selectOptionBottom">
                  <label> Advised by </label>
                  <div
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest &&
                        f.advisory_doctor_id.errors
                    }"
                  >
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      id="otAdviceBy"
                      data-live-search="true"
                      formControlName="advisory_doctor_id"
                      (change)="fetchDoctorDepart($event.target.value)"
                    >
                      <option value="">select</option>
                      <option *ngFor="let doc of oRDoctors" [value]="doc.id">
                        {{ doc?.first_name }} {{ doc?.last_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12 p-l-0">
                <div class="form-group selectOptionBottom">
                  <label> Department </label>
                  <div
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && f.department_id.errors
                    }"
                  >
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      id="otDepartment"
                      data-live-search="true"
                      formControlName="department_id"
                      (change)="fetchDepartDoctors($event.target.value)"
                    >
                      <option value="">select</option>
                      <option *ngFor="let dep of department" [value]="dep.id">
                        {{ dep.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-12 p-l-0">
                <div class="form-group selectOptionBottom">
                  <label> Paediatrician </label>
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      id="paediation"
                      data-live-search="true"
                      formControlName="paediation"
                    >
                      <option value="">select</option>
                      <option *ngFor="let doc of oRDoctors" [value]="doc.id">
                        {{ doc?.first_name }} {{ doc?.last_name }}
                      </option>
                    </select>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-12 p-l-0">
                <div class="form-group">
                  <label>
                    Operation Name <span class="text-danger">*</span>
                  </label>
                  <div [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && f.ot_service_id.errors
                    }">
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      formControlName="ot_service_id"
                      id="operationName"
                      data-conatiner="body"
                      data-live-search="true"
                    >
                      <option value="">select</option>
                      <option
                        *ngFor="let service of otServices"
                        [value]="service.id"
                      >
                        {{ service.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!--                    </div>-->

              <!--                <div class="row my-3">-->
              <div class="col-md-4 col-sm-6 col-12 p-l-0">
                <div class="form-group">
                  <label>
                    Anesthesia Type <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="anesthesia_type_id"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest &&
                        f.anesthesia_type_id.errors
                    }"
                  >
                    <option value="">select</option>
                    <option
                      *ngFor="let anesthesia of anesthesiaTypes"
                      [value]="anesthesia.id"
                    >
                      {{ anesthesia.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12 p-l-0">
                <div class="form-group">
                  <label
                  >Suggested Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    formControlName="suggested_date"
                    [ngModel]="confirmDate | date: 'yyyy-MM-dd'"
                  />
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12 p-l-0">
                <div class="form-group">
                  <label
                  >Suggested Time <span class="text-danger">*</span></label
                  >
                  <input
                    type="time"
                    class="form-control form-control-sm"
                    id="suggested_time"
                    formControlName="suggested_time"
                    [ngModel]="confirmTime | date: 'HH:mm'"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && f.suggested_time.errors
                    }"
                  />
                </div>
              </div>

              <!--                </div>-->

              <!--                    <div class="row my-3">-->

              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label
                  >Estimated Duration Type
                    <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control form-control-sm"
                    formControlName="est_dur_type_id"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && f.est_dur_type_id.errors
                    }"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let duration of durations"
                      [value]="duration.id"
                    >
                      {{ duration.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label
                  >Estimated Duration
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="est_dur_value"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && f.est_dur_value.errors
                    }"
                  />
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Urgency <span class="text-danger">*</span></label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="urgency_id"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitoperationRequest && f.urgency_id.errors
                    }"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let urgency of urgency"
                      [value]="urgency.id"
                    >
                      {{ urgency.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Operation Room</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="operation_room_id"
                  >
                    <option value="" [selected]="true">Select</option>
                    <option [value]="or.id" *ngFor="let or of operationRooms">
                      {{ or.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Case Type <span class="text-danger">*</span></label>
                  <select [ngClass]="{'is-invalid':isSubmitoperationRequest && f.case_type.errors}"
                          class="form-control form-control-sm"
                          formControlName="case_type"
                  >
                    <option value="" [selected]="true">Select</option>
                    <option value="dirty_case">
                      Dirty Case
                    </option>
                    <option value="clean_case">
                      Clean Case
                    </option>
                  </select>
                </div>
              </div>
<!--              Hep. B & Hep. C Screening-->
              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Hep. B & Hep. C Screening </label>
                  <select
                          class="form-control form-control-sm"
                          formControlName="screening"
                  >
                    <option value="" [selected]="true">Select</option>
                    <option value="Already Done">
                      Already Done
                    </option>
                    <option value="Scheduled">
                      Scheduled
                    </option>
                    <option value="On Arrival">
                      On Arrival
                    </option>
                  </select>
                </div>
              </div>
<!--              Blood & Blood Products-->
              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Blood & Blood Products </label>
                  <select
                          class="form-control form-control-sm"
                          formControlName="blood_product"
                  >
                    <option value="" [selected]="true">Select</option>
                    <option value="Not Required">
                      Not Required
                    </option>
                    <option value="Bleed & Stored">
                      Bleed & Stored
                    </option>
                    <option value="Donor Needed">
                      Donor Needed
                    </option>
                  </select>
                </div>
              </div>
<!--              No. of Bags Required-->
              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>No. of Bags Required </label>
                  <input type="text" class="form-control form-control-sm" maxlength="2" placeholder="0" formControlName="number_of_bags" (keyup)="bagsValueValidate($event.target.value)" (keypress)="_onlyNumeric($event)">
                </div>
              </div>
<!--              Remarks-->
              <div class="col-md-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Remarks</label>
                  <textarea
                    type="text"
                    rows="4"
                    class="form-control-sm form-control"
                    placeholder="Remarks"
                    formControlName="remarks"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- PRODUCT ARRAY -->
              <div class="col-lg-8 col-md-8 col-sm-4 col-12">
                <div class="form-group">
                  <label>Instruments</label>
                  <app-product-search
                    [apiUrl]="'/inventory/get_product_list'"
                    [type]="'ot-calendar'"
                    (getProductId)="getProduct($event)"
                    [resetSearchInstrument]="resetSearchInstrument"
                  ></app-product-search>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                <div class="form-group">
                  <label
                  >Time (hh:mm)</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="intrumentTime"
                    data-toggle="datetimepicker"
                    data-target="#intrumentTime"
                    [(ngModel)]="instrumentTime"
                    [ngModelOptions]="{ standalone: true }"
                    (keydown)="preventChangeDate()"
                  />
                </div>
              </div>
              <div class="col-md-1 col-sm-1 col-12 mt-5">
                <div class="form-group">
                  <button
                    (click)="addToIntrumentArray()"
                    type="button"
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x">
                      <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"/>
                          <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                          <path
                            d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <ng-container *ngFor="let data of instrumentArray; let i = index">
                <div class="col-lg-8 col-md-8 col-sm-4 col-12 mt-3">
                  <span>{{ data?.name }}</span>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-12 mt-3">
                  <span>{{ data?.time }}</span>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 col-12 mt-3 text-center">
                  <span>
                    <i
                      class="ki ki-close text-muted; cursor-pointer"
                      style="font-size: 0.75rem"
                      (click)="deleteProduct(i)"
                    ></i>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="modal-footers">
            <div>
              <app-upload-images
                [documentType]="'document'"
                [isUploadImage]="true"
                (imageData)="sendImages($event)"
              ></app-upload-images>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-sm btn-secondary mr-2"
                (click)="c('Close click'); resetOperationRequestForm()"
              >
                Close
              </button>
              <button type="submit" class="btn btn-sm btn-primary" [disabled]="isSubmit">Save</button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
    <!--        <button class="btn btn-brand" (click)="open(content5)">Launch demo modal</button>-->
  </div>
</div>
