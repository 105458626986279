import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BirthCertificate} from '../../../_models/birth-certificate';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {BirthCertificateService} from '../../../../services/birth-certificate.service';
import {formatDate} from '@angular/common';
import {StaffService} from '../../../../services/staff.service';
import {HospitaldoctorService} from '../../../../services/hospitaldoctor.service';
import {PatientService} from '../../../../services/patient.service';
import {ConfiguratorsService} from "../../../../services/configurators.service";
import * as moment from "moment/moment";

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-birth-certificate',
  templateUrl: './birth-certificate.component.html',
  styleUrls: ['./birth-certificate.component.css'],
})
export class BirthCertificateComponent implements OnInit {
  @Input() patient: any;
  @Input() doctors: any;

  BirthCertificateForm: UntypedFormGroup;
  ParentPatientSearchForm: UntypedFormGroup;

  birth_certificate: BirthCertificate;
  submitted = false;
  disableSubmitBtn = false;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  staffNurses = [];
  gender: any;
  currentTime: any;
  patient_age: any;
  selected_age_type = 'hours';
  age_value: any;
  patient_dob: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private birthCertificateService: BirthCertificateService,
    private staffService: StaffService,
    private patientService: PatientService,
    private hospitalDoctorsService: HospitaldoctorService,
    private configuratorsService: ConfiguratorsService,
  ) {
  }

  ngOnInit(): void {
    this.BirthCertificateForm = this.formBuilder.group({
      father_id: [''],
      mother_id: [''],
      ward_id: [''],
      father_first_name: [''],
      father_last_name: [''],
      father_cnic: [''],
      profession: [''],
      phone_number: [''],
      comments: [''],
      last_menstruation_date: [''],
      baby_weight: [''],
      gestational_age: [''],
      doctor_conducting_delivery: [''],
      neonatal_doctor: [''],
      meconium_aspiration_syndrome: [false],
      doctor_not_attended_delivery: [false],
      birth_type: ['', Validators.required],
      caesarian_number: [''],
      rank_of_pregnancy: ['', Validators.required],
      gender: [''],
      time_of_birth: [''],
      birth_day: [''],
      birth_month: [''],
      birth_year: [''],
      age: [''],
      dob: [''],
      pat_age_type: ['hours'],
      pat_age_value: [
        '',
        [Validators.required, Validators.min(0), Validators.max(150)],
      ],
      mortality: [false],
      morbidity: [false],
      request_status: ['requested'],
    });
    this.ParentPatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: [''],
    });
    this.fetchNursingStaff();
    this.configuratorDetails('gender');

    setTimeout(() => {
      $('.doctorSelectpicker').selectpicker();
    }, 500);
    const now = new Date();
    this.currentTime = now.toTimeString().slice(0, 5); // HH:MM format
    this.BirthCertificateForm.patchValue({time_of_birth: this.currentTime});
  }
  setDOBfromAge(): void {
    const dob = moment().subtract(
      this.BirthCertificateForm.value.pat_age_value,
      this.BirthCertificateForm.value.pat_age_type,
    );
    this.patient_dob = dob.format('MM-DD-YYYY'); // Format the date
    this.BirthCertificateForm.get('dob').setValue(this.patient_dob);
  }
  configuratorDetails(type): any {
    this.gender = [];
    this.configuratorsService.fetchConfigurationV2(type).subscribe(
      (data) => {
        this.gender = data.configurator_types;
        setTimeout(() => {
          $('#gender').selectpicker('refresh');
          $('#gender').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.message.message);
      },
    );
  }

  saveBirthCertificate(): any {
    this.disableSubmitBtn = true;
    this.submitted = true;
    if (this.BirthCertificateForm.invalid || !this.patient) {
      this.disableSubmitBtn = false;
      return;
    }
    if (
      this.BirthCertificateForm.get('doctor_conducting_delivery').value === null
    ) {
      this.BirthCertificateForm.get('doctor_conducting_delivery').setValue('');
    }
        if (this.BirthCertificateForm.value.dob) {
      this.BirthCertificateForm.get('birth_month').setValue(
        this.BirthCertificateForm.value.dob.split('-')[0],
      );
      this.BirthCertificateForm.get('birth_day').setValue(
        this.BirthCertificateForm.value.dob.split('-')[1],
      );
      this.BirthCertificateForm.get('birth_year').setValue(
        this.BirthCertificateForm.value.dob.split('-')[2],
      );
    }
    if (this.BirthCertificateForm.value.pat_age_type != '') {
      this.BirthCertificateForm.get('age').setValue(
        this.BirthCertificateForm.value.pat_age_value +
          ' ' +
          this.BirthCertificateForm.value.pat_age_type,
      );
    } else {
      this.BirthCertificateForm.get('age').setValue(
        this.BirthCertificateForm.value.pat_age_value +
          ' ' +
          this.selected_age_type,
      );
    }
    this.BirthCertificateForm.get('pat_age_value').setValue(Number(this.BirthCertificateForm.get('pat_age_value').value));
    this.BirthCertificateForm.get('request_status').setValue('requested');
    this.birth_certificate = this.BirthCertificateForm.value;
    this.ngxLoader.start();
    this.birthCertificateService
      .createBirthCertificateV2(this.birth_certificate, this.patient)
      .subscribe(
        (data) => {
          this.birth_certificate = data.birth_certificate;
          this.ngxLoader.stop();
          this.BirthCertificateForm.reset();
          this.submitted = false;
          this.disableSubmitBtn = false;
          toastr.success('Submitted');
          this.BirthCertificateForm.patchValue({
            time_of_birth: this.currentTime,
            gender: '',
            pat_age_type: 'hours',
            last_menstruation_date: '',
            birth_type: '',
          });
          setTimeout(() => {
            $('#gender').selectpicker('refresh');
            $('#gender').selectpicker();
          }, 500);
        },
        (err) => {
          this.disableSubmitBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  familyPatient(): any {
    this.ParentPatientSearchForm.value.search_query =
      this.BirthCertificateForm.value.father_id;
    if (this.ParentPatientSearchForm.value.search_query.length == 11) {
      this.ParentPatientSearchForm.value.type = 'phone1';
    } else if (this.ParentPatientSearchForm.value.search_query.length == 8) {
      this.ParentPatientSearchForm.value.type = 'mrn';
    } else if (this.ParentPatientSearchForm.value.search_query.length == 13) {
      this.ParentPatientSearchForm.value.type = 'patient_nic';
    }
    if (this.BirthCertificateForm.get('father_id').value.length === 8) {
      this.fetchFamilyPatients();
    } else {
      this.BirthCertificateForm.patchValue({
        father_first_name: '',
        father_last_name: '',
        phone_number: '',
        father_cnic: '',
      });
    }
  }

  fetchFamilyPatients(): any {
    this.ngxLoader.start();
    this.patientService
      .searchPatientByTypeAdmissionV2(this.ParentPatientSearchForm.value)
      .subscribe(
        (data) => {
          if (data.patients) {
            this.BirthCertificateForm.patchValue({
              father_first_name: data.patients[0]?.first_name,
              father_last_name: data.patients[0]?.last_name,
              phone_number: data.patients[0]?.phone1,
              father_cnic: data.patients[0]?.patient_nic,
            });
            this.birth_certificate = this.BirthCertificateForm.value;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // onlyNumericAllowDecimal(event: any): any {
  //   if (event.charCode != 46) {
  //     const pattern = /^\d{1,6}(\.\d{1,2})?$/;
  //     const inputChar = String.fromCharCode(event.charCode);
  //     if (!pattern.test(inputChar)) {
  //       // invalid character, prevent input
  //       event.preventDefault();
  //     }
  //   }
  // }
  maxLengthOrAllowDecimal(event: any): any {
    const maxLengthBeforeDecimal = 3;
    const value = event.target.value;
    const decimalIndex = value.indexOf('.');

    if (decimalIndex !== -1 && decimalIndex <= maxLengthBeforeDecimal) {
      const valueAfterDecimal = value.substring(decimalIndex + 1);
      if (valueAfterDecimal.length >= 3) {
        event.preventDefault();
      }
    } else if (value.length >= maxLengthBeforeDecimal) {
      event.preventDefault();
    }
  }

  positiveNoWithDecimal(e: any, decimal: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    const value = String(e.target.value);
    const index = value.indexOf('.');
    const val = value.split('.');
    if (inputChar === '.' && value.includes('.')) {
      event.preventDefault();
      return false;
    }
    if (inputChar === '.') {
      return true;
    }
    if (index >= 0 && val.length > 1) {
      if (val[1].length >= decimal - 1) {
        event.preventDefault();
        return false;
      }
    }
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  get b(): any {
    return this.BirthCertificateForm.controls;
  }

  daysOfBaby(value: any): any {
    if (value > 300) {
      this.BirthCertificateForm.get('gestational_age').patchValue(300);
    }
  }

  fetchNursingStaff(): any {
    this.staffNurses = [];
    this.staffService.getNursingStaff().subscribe(
      (data) => {
        this.staffNurses = data;
        setTimeout(() => {
          $('.doctorConductingDelivery').selectpicker();
          $('#givenByPicker').selectpicker('refresh');
          $('#givenByPicker').selectpicker();
          $('#checkedByPicker').selectpicker('refresh');
          $('#checkedByPicker').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
}
