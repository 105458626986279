import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OpdService } from '../../../../services/opd.service';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { DepartmentsbyhospitalService } from '../../../../services/departmentsbyhospital.service';
import { BillingCategoryService } from '../../../../services/billing-category.service';
import { OtService } from '../../../../services/ot.service';
import { EmployeeRegistrationService } from '../../../../services/employee-registration.service';
import { environment } from '../../../../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-operation-request',
  templateUrl: './operation-request.component.html',
  styleUrls: ['./operation-request.component.css'],
})
export class OperationRequestComponent implements OnInit {
  @ViewChild('cancelOperationRequest') cancelOperationRequest: ElementRef;
    @ViewChild('content5') modal: ElementRef;
  ngbModalStatic: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg',
  };

  @Input() patient: any;
  @Input() visitType: any;
  @Input() oRDoctors: any;
  @Input() isAdd: boolean;

  operationRequestForm: UntypedFormGroup;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  isSubmitoperationRequest = false;
  department = [];
  otServices = [];
  operationRooms = [];
  anesthesiaTypes = [];
  durations = [];
  urgency = [];
  allOperationsRequests = [];
  cancelRequestID: number;
  Doctors = [];
  currentDate = new Date();
  docs = [];
  idForStoringDocs: any;
  confirmDate = this.today;
  confirmTime = '';
  instrumentTime = '';
  instrumentName = '';
  instrumentID = '';
  resetSearchInstrument = false;
  instrumentArray = [];
  selectedSuggestedTime: any;
  isSubmit = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private opdService: OpdService,
    private hospitalDoctorsService: HospitaldoctorService,
    private departmentService: DepartmentsbyhospitalService,
    private billingService: BillingCategoryService,
    private otService: OtService,
    private employeeService: EmployeeRegistrationService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
  this.operationRequestForm = this.formBuilder.group({
      patient_id: ['', Validators.required],
      advisory_doctor_id: ['', Validators.required],
      department_id: ['', Validators.required],
      ot_service_id: ['', Validators.required],
      anesthesia_type_id: ['', Validators.required],
      suggested_date: [this.today, Validators.required],
      suggested_time: ['', Validators.required],
      est_dur_value: ['', Validators.required],
      est_dur_type_id: ['', Validators.required],
      urgency_id: ['', Validators.required],
      paediation: [''],
      screening: [''],
      number_of_bags: [''],
      remarks: [''],
      blood_product: [''],
      operation_room_id: [''],
      case_type: ['', Validators.required],
      type: ['individual'],
    });

  this.fetchOTServices();
    // this.fetchDoctor();
  this.fetchDepartments();
  this.fetchOperationRooms();
  this.fetchAnesthesiaTypes();
  this.fetchDurations();
  this.fetchUrgency();
  this.fetchOperationRequests('');
  this.resetOperationRequestForm();
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.fetchOperationRequests(changes.patient.currentValue);
  }
    getInstrumentTime(): any {
    // Check if the datetimepicker is already initialized
      if (!$('#intrumentTime').data('datetimepicker')) {
        setTimeout(() => {
          const todayTime = moment().format('HH:mm'); // Ensure only HH:mm format

          $('#intrumentTime').datetimepicker({
            format: 'HH:mm', // Ensure 24-hour format
            useCurrent: false,
            autoClose: true,
            ignoreReadonly: true,
            defaultDate: moment(), // Use moment() but ensure only time is extracted
          });

          $('#intrumentTime').val(todayTime); // Set formatted time
          this.instrumentTime = todayTime;

          // Ensure only time is captured on change
          $('#intrumentTime').on('change.datetimepicker', (e) => {
            const selectedTime = moment(e.date).format('HH:mm'); // Extract only time
            this.instrumentTime = selectedTime;
            $('#intrumentTime').val(selectedTime); // Ensure the field shows only HH:mm
          });
        }, 500);
      }
    }
    openRequestModal(): any {
    // document.getElementById('currentDate').value = formatDate(date);
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 100);
    this.modalService.open(this.modal, this.ngbModalStatic);
    // if (this.activeEvent.allDay === true) {
    this.confirmTime =
        String(new Date().getHours()).padStart(2, '0') + ':' +
        String(new Date().getMinutes()).padStart(2, '0');
    console.log('confirmTime', this.confirmTime);
    setTimeout(() => {
        this.operationRequestForm
          .get('suggested_time')
          .patchValue(this.confirmTime);
      }, 100);
    // }
    // else {
    //   const times = moment(this.activeEvent.startStr).format('HH:mm');
    //   setTimeout(() => {
    //     this.operationRequestForm.get('suggested_time').patchValue(times);
    //   }, 100);
    // }
    if (!$('#intrumentTime').data('datetimepicker')) {
      this.getInstrumentTime();
    }
    setTimeout(() => {
      $('#paediation').selectpicker('refresh');
      $('#paediation').selectpicker('');
      $('#otAdviceBy').selectpicker('refresh');
      $('#otAdviceBy').selectpicker('');
      $('#otDepartment').selectpicker('refresh');
      $('#otDepartment').selectpicker('');
      $('#operationName').selectpicker('refresh');
      $('#operationName').selectpicker('');
    }, 500);
  }
  getProduct(product): any {
    this.instrumentName = product?.name;
    this.instrumentID = product?.id;
  }

  addToIntrumentArray(): any {
    if (!this.instrumentName || !this.instrumentTime) {
      toastr.error('Add Instruments');
      return;
    }
    const duplicate = this.instrumentArray.find(t => t.id === Number(this.instrumentID));
    if (duplicate) {
      toastr.error('Duplicate Not Allowed');
      return;
    }
    if (this.instrumentName && this.instrumentTime) {
      this.instrumentArray.push({
        name: this.instrumentName,
        id: this.instrumentID,
        time: this.instrumentTime,
      });
      this.resetSearchInstrument = true;
      setTimeout(() => {
        this.resetSearchInstrument = false;
        const newTime = new Date();
        $('#intrumentTime').datetimepicker('date', newTime);
        const resetTime = moment(newTime).format('HH:mm');
        $('#intrumentTime').val(resetTime);
        this.instrumentTime = resetTime;
      }, 500);
    }
  }

  deleteProduct(i): any {
    this.instrumentArray.splice(i, 1);
  }
  _onlyNumeric(e): any {
    let charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  bagsValueValidate(value): void {
    const bagValue = Number(value);
    if (bagValue > 15) {
      this.operationRequestForm.patchValue({number_of_bags: 15});
    }
  }
  fetchUrgency(): any {
    this.urgency = [];
    this.employeeService.fetchConfigurationV2('urgency').subscribe(
      (data) => {
        this.urgency = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDurations(): any {
    this.durations = [];
    this.employeeService.fetchConfigurationV2('est_dur_type').subscribe(
      (data) => {
        this.durations = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchAnesthesiaTypes(): any {
    this.anesthesiaTypes = [];
    this.employeeService.fetchConfigurationV2('anesthesia_type').subscribe(
      (data) => {
        this.anesthesiaTypes = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.indexDepartmentsv2().subscribe(
      (data) => {
        this.department = data.departments;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#otDepartment').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchOperationRooms(): any {
    this.operationRooms = [];
    this.ngxLoader.start();
    this.otService.getOperationRoomsV2().subscribe(
      (data) => {
        this.operationRooms = data.operation_rooms;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchOTServices(): any {
    this.ngxLoader.start();
    this.otServices = [];
    this.billingService.getAllOtServicesV2().subscribe(
      (data) => {
        this.otServices = data.ot_services;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#otServiceName').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  // onSubmitoperationRequest(): any {
  //   const time = this.operationRequestForm.get('suggested_time').value;
  //   if (time) {
  //     const taken = moment(time, 'HH:mm:ss');
  //     const takenTime = moment(taken).utc().format('H:mm');
  //     this.operationRequestForm.get('suggested_time').patchValue(takenTime);
  //   }
  //   // this.consultationForm.get('patient_id').patchValue(this.patient.id);
  //   // this.consultationForm.get('status').patchValue('ipd');
  //
  //   this.isSubmitoperationRequest = true;
  //   if (this.operationRequestForm.invalid) {
  //     return;
  //   }
  //   this.operationRequestForm.get('type').patchValue('');
  //
  //   this.ngxLoader.start();
  //   this.opdService
  //     .addOperationRequestV2(this.operationRequestForm.value, this.patient)
  //     .subscribe(
  //       (data) => {
  //         this.ngxLoader.stop();
  //         this.idForStoringDocs = data.id;
  //         this.resetOperationRequestForm();
  //         this.sendDocs();
  //         this.fetchOperationRequests('');
  //         toastr.success('Operation Request Added');
  //       },
  //       (err) => {
  //         this.ngxLoader.stop();
  //         toastr.error(err.error.message);
  //       },
  //     );
  // }
   onSubmitOperationRequest(): any {
    this.operationRequestForm.get('patient_id').patchValue(this.patient.id);
    this.isSubmitoperationRequest = true;
    if (this.operationRequestForm.invalid) {
      return;
    }
    this.isSubmit = true;
    this.selectedSuggestedTime = this.operationRequestForm.get('suggested_time').value;
    const time = this.selectedSuggestedTime;
    if (time) {
      const taken = moment(time, 'HH:mm:ss');
      const takenTime = moment(taken).utc().format('H:mm');
      this.operationRequestForm.get('suggested_time').patchValue(takenTime);
    }
    const formData = {
      ...this.operationRequestForm.value, // Existing form data
      instruments: this.instrumentArray  // Add the array before sending
    };
    this.ngxLoader.start();
    this.otService
      .addOperationRequestV2(
        formData,
        this.operationRequestForm.value.patient_id,
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.modal);
          this.idForStoringDocs = data.id;
          this.resetOperationRequestForm();
          this.sendDocs();
          this.isSubmit = false;
          this.fetchOperationRequests('');
          toastr.success('Operation Request Added');
          this.ngxLoader.stop();
        },
        (err) => {
          this.operationRequestForm.get('suggested_time').setValue(this.selectedSuggestedTime);
          this.ngxLoader.stop();
          this.isSubmit = false;
          toastr.error(err.error.message);
        },
      );
  }

  fetchDoctorDepart(id: any): any {
    const departId = this.oRDoctors.filter((t) => t.id === Number(id))[0]
      .doctor_department_id;
    if (departId) {
      this.operationRequestForm.get('department_id').patchValue(departId);
    } else {
      this.operationRequestForm.get('department_id').patchValue('');
    }
    setTimeout(() => {
      $('#otDepartment').selectpicker('refresh');
    }, 200);
  }

  get f(): any {
    return this.operationRequestForm.controls;
  }

   resetOperationRequestForm(): any {
    this.operationRequestForm.reset({
      paediation: '',
      screening: '',
      blood_product: '',
      number_of_bags: '',
      remarks: '',
      advisory_doctor_id: '',
      department_id: '',
      ot_service_id: '',
      anesthesia_type_id: '',
      suggested_date: this.today,
      suggested_time: '',
      est_dur_value: '',
      est_dur_type_id: '',
      urgency_id: '',
      operation_room_id: '',
      case_type: '',
      type: 'individual',
    });
    this.instrumentArray = [];
    this.isSubmitoperationRequest = false;
  }
  preventChangeDate(): any {
    return false;
  }
  fetchOperationRequests(val): any {
    // console.log('va;lllllllllllllll', val);
    this.ngxLoader.start();
    this.allOperationsRequests = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getOperationRequestsV2(this.patient, this.visitType)
      .subscribe(
        (data) => {
          this.allOperationsRequests = data.operation_requests;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  fetchDepartDoctors(value: any): any {
    this.operationRequestForm.get('advisory_doctor_id').patchValue('');
    this.Doctors = [];
    if (value) {
      this.ngxLoader.start();
      this.hospitalDoctorsService
        .fetchDepartmentDoctorListV2(Number(value))
        .subscribe(
          (data) => {
            this.Doctors = data.users;
            this.ngxLoader.stop();
            setTimeout(() => {
              $('#otAdviceBy').selectpicker('refresh');
            }, 500);
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }

  printPatientoperationRequest(id): any {
    window.open(
      'pdf-reports/patient-operation-request-report?id=' +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&invoice_id=' +
        '&request_id=' +
        id,
    );
    // window.open(environment.rorUrl + '/v4/operation_requests/print_report.pdf?id=' + id + '&auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'));
  }

  cancelPatientoperationRequest(id): any {
    this.cancelRequestID = id;
    this.modalService.open(this.cancelOperationRequest, this.ngbModalStatic);
  }

  cancelRequest(): any {
    this.modalService.dismissAll(this.cancelOperationRequest);
    this.ngxLoader.start();
    this.opdService
      .cancelPrintOperationRequestV2(this.patient, this.cancelRequestID)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.fetchOperationRequests('');
          toastr.success('Request Cancelled');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  sendImages(event): any {
    this.docs = event;
  }

  sendDocs(): any {
    this.ngxLoader.start();
    this.otService
      .addDocumentInOperationRequests(this.docs, this.idForStoringDocs)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.docs = [];
        },
        (err) => {
          this.docs = [];
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
}
