import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {PatientService} from '../../../services/patient.service';
import {ServiceService} from '../../../services/service.service';
import {HospitaldoctorService} from '../../../services/hospitaldoctor.service';
import {DepartmentsbyhospitalService} from '../../../services/departmentsbyhospital.service';
import {LookupService} from '../../../services/lookup.service';
import {PricingService} from '../../../services/pricing.service';
import {HospitalService} from '../../../services/hospital.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ShareDataService} from '../../../services/share-data.service';
import {UserService} from '../../../services/user.service';
import {SystemControlsService} from '../../../services/system-controls.service';
import {EmployeeVisitService} from '../../../services/employee-visit.service';
import {ConfiguratorsService} from '../../../services/configurators.service';
import {ConfigurationService} from '../../../services/configuration.service';
import * as moment from 'moment';
import {LocationService} from '../../../services/location.service';
import {FinanceConfigurationService} from '../../../services/finance-configuration.service';
import {DoctorConfigurationService} from '../../../services/doctor-configuration.service';
import {CURRENCY_FORMAT_KEY} from '../../../utilities/constant';
import {AgePipe} from '../../../custom-pipes/age.pipe';
import {Location} from '@angular/common';
import {LoanService} from '../../../services/loan.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-services-new',
  templateUrl: './services-new.component.html',
  styleUrls: ['./services-new.component.css'],
})
export class ServicesNewComponent implements OnInit {

  @ViewChild('serviceAddConfirm') serviceAddConfirm: ElementRef;
  @ViewChild('updatePerformingModal') updatePerformingModal: ElementRef;
  @ViewChild('updateReferringModal') updateReferringModal: ElementRef;
  private modalRef: NgbModalRef | null = null;
  current_environment = environment.rorUrl;
  requiredFields = 'id,allow_dup_service_types';
  PatientSearchForm: UntypedFormGroup;
  PatientSearchTabForm: UntypedFormGroup;
  employeeVisitForm: UntypedFormGroup;
  historyTab = false;
  servicePaidForm = {
    patient_id: '',
    mode_of_payment: 'Cash',
    payment_details: '',
    invoice_ids: [],
    receivable: 0,
    cash: 0,
    ledger: '',
    receivable_percentage: 0,
  };
  serviceForm = {
    patient_id: '',
    mode_of_payment: 'Cash',
    payment_details: '',
    bank_transfer: '',
    transaction_id: '',
    patient_services: [],
    taxes: [],
    discount: 0,
    discount_percentage: 0,
    receivable: 0,
    cash: 0,
    ledger: '',
    reference: '',
  };
  consultation_services = [
    {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      comments: '',
      department_id: '',
      sub_department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      price: 0,
      discount: 0,
      discount_per: 0,
      service_price: 0,
      actual_price: 0,
      quantity: 0,
      urgent_new: '',
      quantity_allowed: false,
      editable: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
      clinic_name: '',
    },
  ];
  patient_services = [
    {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      sub_department: '',
      service_dept: '',
      comments: '',
      department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      quantity: 0,
      service_price: 0,
      actual_price: 0,
      price: 0,
      discount: 0,
      discount_per: 0,
      urgent_new: 'routine',
      quantity_allowed: false,
      editable: false,
      installment_enabled: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
      employee_type: '',
      commentField: false,
      requested_service_type: '',
      requested_service_id: '',
      new_requested_service: false,
      patient_partial_payment_ids: [],
    },
  ];

  doctors = [];
  doctorsAll = [];
  services = [];
  allServices = [];
  servicesMain = [];
  departments = [];
  subDepartments = [];
  lookups = [];
  ledgersList: any;
  uniq_services = [];
  partialServices = [];
  partialSerDuePayment = 0;
  patients = [];
  current_service_type: any;
  patient: any;
  consultantionTotal = 0;
  totalTaxValue = 0;
  servicesTotal = 0;
  patientTypeCredit = false;
  patientServiceHistory = [];
  patientServiceHistoryBackup = [];
  activeTab = 'Vitals';
  all_invoices_checked = false;
  paymentStatus = 'paid';
  isAdmitted = false;
  isChecked = false;
  currentControls: any;
  currentHospital = environment.hospital;
  isSubmitted = false;
  selectedPerformingID: number;
  selectedReferringID: number;
  serviceId: number;
  paymentTypeDisabled = false;
  hideUnPaid = false;
  screenControls = [];
  defaultServiceType = '';
  defaultServiceName = '';
  requestedServices = false;
  requestedServicesList = [];
  selectedReportingIndex = 0;
  isSubmitReferring = false;
  genderList = [];
  designationList = [];
  titleList = [];
  staffTypeList = [];
  refDoctorsAll = [];
  countries = [];
  states = [];
  cities = [];
  allEmployeesStaffTypeDoctor = [];
  showPriceOnService = true;
  changeTitleOfPrefDoctor = true;
  changeTextToRequestedDoctor = false;
  showElecgiesFields = false;
  anyContraindicationsToReceiveContrast = false;
  anyAllergiesToIodinatedContrast = false;
  anyContraindicationsToReceiveContrastTextarea = '';
  userInfo = '';
  subDepartmentsAgainstServiceType = [];
  addDefaultOtherService = false;
  showConsultantCard = false;
  showServiceCard = false;
  serviceType: any;
  subDepartment: any;
  allowSystemDiscount = false;
  serviceTypeDisable = false;
  allowServiceZeroPrice = false;
  allowVisitZeroPrice = false;
  callDefaultServiceArray = false;
  disableAddServiceMainBtn = false;
  show_service_invoice = false;
  hide_billing_history_invoice_short = false;
  visitType = [];
  invoiceControl: any;
  restrictPaidInvoiceForIPD = false;
  blockedDuplicateServices: any;
  roomNumberUpdated: any;
  doctorRoomNumber: any;
  doctor_id: any;
  isUpdateRoomNumber = false;
  currencyFormat = CURRENCY_FORMAT_KEY;
   bankList: any;
  taxesData = [];
  showRegistrationCard = false;
  current_environment_python: string;
  current_url = '';
  isPatients = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private chRef: ChangeDetectorRef,
    private patientService: PatientService,
    private serviceService: ServiceService,
    private hospitalDoctorsService: HospitaldoctorService,
    private departmentService: DepartmentsbyhospitalService,
    private lookupService: LookupService,
    private pricingService: PricingService,
    private hospitalService: HospitalService,
    private modalService: NgbModal,
    private shareDataService: ShareDataService,
    private userService: UserService,
    private systemControlsService: SystemControlsService,
    private fb: UntypedFormBuilder,
    private visitService: EmployeeVisitService,
    private configuratorsService: ConfiguratorsService,
    private configurationService: ConfigurationService,
    private locationService: LocationService,
    private financeConfigService: FinanceConfigurationService,
    private departmentsByHospitalService: DepartmentsbyhospitalService,
    private systemControlService: SystemControlsService,
    private doctorConfigService: DoctorConfigurationService,
    private agePipe: AgePipe,
    private location: Location,
    private loan: LoanService
  ) {
    this.current_environment_python = environment.pythonUrl;
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      event.stopPropagation();
      this.addServiceConfirm();
    }
  }

  calculateDiscountAmount(value): any {
    const total = this.servicesTotal + this.consultantionTotal + this.totalTaxValue;
    if (value === 'amount') {
      if (Number(this.serviceForm.discount) >= Number(total)) {
        this.serviceForm.discount = total;
        this.serviceForm.discount_percentage = 100;
      }
      const per = (this.serviceForm.discount / total) * 100;
      this.serviceForm.discount_percentage = parseFloat(per.toFixed(2));
    } else {
      if (this.serviceForm.discount_percentage >= 100) {
        this.serviceForm.discount = this.serviceForm.cash;
        this.serviceForm.discount_percentage = 100;
      }
      const per = (total * this.serviceForm.discount_percentage) / 100;
      this.serviceForm.discount = Math.round(per);
    }
    this.serviceForm.cash = total - this.serviceForm.discount;
    this.updateServiceReceivable();
  }
  resetForm(patient, fromSearch, checkPatient): any{
    if (fromSearch && !checkPatient) {
      if (patient?.mrn === this.patient?.mrn) {
        return;
      }
    } else if (!fromSearch && !checkPatient) {
      if (patient === this.patient) {
        return;
      }
    }
    this.subDepartments[0] = [];

    this.subDepartmentsAgainstServiceType[0] = [];
    this.PatientSearchForm = this.formBuilder.group({
      search_query: [fromSearch ? patient?.mrn : patient],
      type: ['mrn'],
    });
    this.fetchPatient();
    if (fromSearch){
      this.consultation_services = [];
      this.patient_services = [];
      this.servicesTab();
      this.updatePrice();
      this.showRegistrationCard = false;
      this.showElecgiesFields = false;
      this.isAdmitted = patient?.admitted;
      if (this.isAdmitted){
          this.showConsultantCard = false;
        } else  {
          this.showConsultantCard = true;
        }
    }
    this.closePatientList();
  }
  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }
  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }
  verifyThumbImpression(): any {
    const patientData = {
      is_attendance: 0,
      patient_id: '',
      redirect_url:
        window.location.origin + '/patient/registration-v2?patient_mrn=',
      api_url: '',
      data_url:
        this.current_environment_python +
        '/api/get_patient_for_fingerprint_verification?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      background_image:
        this.current_environment_python +
        '/hr/get_background_image?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
    };
    const jsonData = JSON.stringify(patientData);
    const utf8Bytes = new TextEncoder().encode(jsonData);
    const base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));
    window.open('digitalpersona:' + base64String, '_blank');
  }
  filterPatientData(): any {
    if (this.PatientSearchForm.value.type === 'thumb') {
      this.verifyThumbImpression();
      return;
    }
    this.patients = [];
    this.isPatients = false;
    this.ngxLoader.start();
    this.PatientSearchTabForm.get('search_query')?.setValue(
        this.PatientSearchTabForm.get('search_query')?.value.replace(/-/g, ''),
      );
    let referal = false;
    if (this.current_url === '/front-desk') {
      referal = true;
    }
    this.patientService
      .searchPatientRegistrationV2(this.PatientSearchTabForm.value, referal)
      .subscribe(
        (data) => {
          this.patients = data.patients;
          this.isPatients = true;
          if (this.PatientSearchTabForm.value.type === 'patient_nic') {
            $('#patient_search_here').mask('99999-9999999-9').trigger('input');
          } else if (this.PatientSearchTabForm.value.type === 'mrn') {
            $('#patient_search_here').mask('99-##').trigger('input');
          } else {
            $('#patient_search_here')
              .unmask('99999-9999999-9')
              .trigger('input');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  ngOnInit(): any {
    this.resetForm(this.route.snapshot.queryParams.patient_id, false, false);
    this.current_url = this.router.url;
    this.PatientSearchTabForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    this.getScreenControls();
    // this.getInvoiceControls();
    this.getServicesControls();
    this.fetchServicesTypes();
    // this.fetchServices();
    this.fetchDoctors();
    // this.fetchLookup();
    this.leadersAccount();
    this.getBankDetail();
    this.fetchDepartments();
    this.getBillingInvoiceControls();
    setTimeout(() => {
      $('.selectpicker').selectpicker({
        dropupAuto: false,
      });
      // $('.doctorSelectpicker').selectpicker();
    }, 500);
    $('html').click(function() {
      $('.bootstrap-select.open').removeClass('open');
      $('.dropdown-menu').removeClass('show');
    });

    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      if (this.currentControls !== '') {
        if (this.currentControls.patient_services.is_consultation === false) {
          this.isAdmitted = true;
          this.consultation_services = [];
        }
        if (
          this.currentControls.patient_services.disable_system_discount === true
        ) {
          this.allowSystemDiscount = true;
        }
        if (
          this.currentControls.patient_services.disable_service_type === true
        ) {
          this.serviceTypeDisable = true;
        }
        if (
          this.currentControls.patient_services.allow_zero_service_price ===
          true
        ) {
          this.allowServiceZeroPrice = true;
        }
        if (
          this.currentControls.patient_services.allow_zero_visit_price === true
        ) {
          this.allowVisitZeroPrice = true;
        }
      }
      // if (this.currentControls !== '') {
      //   if (this.currentControls.patient_services.is_payment_mode === true) {
      //     this.paymentTypeDisabled = true;
      //   } else {
      //     this.paymentTypeDisabled = false;
      //   }
      // }
    });
    this.employeeVisitForm = this.fb.group({
      title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: [''],
      gender: ['', Validators.required],
      speciality: [''],
      nic: [
        '',
        Validators.compose([
          Validators.minLength(13),
          Validators.maxLength(13),
        ]),
      ],
      pmdc_registration_no: [''],
      primary_contact: [''],
      hospital_name: [''],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      office_number: [''],
      mobile_number: [''],
      staff_type: ['', Validators.required],
      address: ['', Validators.required],
      department: ['', Validators.required],
      designation: ['', Validators.required],
      status: [true],
    });
    // this.getStaffType();
    // this.getGenderList();
    // this.getDesignationList();
    // this.getTitleList();
    this.fetchReferringDoctors();
    this.getFrontDeskControlData();
  }

  fetchRestrictedInvoices(doctorId, index): any{
    const doctorIdNumber = +doctorId;
    const selectedDoctor = this.doctorsAll.find((doctor) => +doctor.id === doctorIdNumber);
    if (selectedDoctor.restrict_panel_invoices === 'all'){
      return;
    }
    if (selectedDoctor.restrict_panel_invoices === 'panel' && !this.patient?.patient_type?.is_panel === true){
      toastr.error('Selected doctor is currently unavailable for panel patients');
      $('#performingDoctor' + index).val('');
    }
    if (selectedDoctor.restrict_panel_invoices === 'private' && this.patient?.patient_type?.is_panel === true){
      toastr.error('Selected doctor is currently unavailable for panel patients');
      $('#performingDoctor' + index).val('');
    }
  }

  historyTabCall() {
    this.historyTab = true;
  }

  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        this.updatePaymentTypeConfig();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updatePaymentTypeConfig(): any {
    if (this.screenControls.length > 0) {
      if (
        this.screenControls.filter((m) => m.code === 'paymenttypedisable')
          .length > 0
      ) {
        this.paymentTypeDisabled = this.screenControls.filter(
          (m) => m.code === 'paymenttypedisable',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'defaultpatienttypeunpaid')
          .length > 0
      ) {
        if (
          this.screenControls.filter(
            (m) => m.code === 'defaultpatienttypeunpaid',
          )[0].is_show === true
        ) {
          this.paymentStatus = 'unpaid';
          this.serviceForm.mode_of_payment = 'Credit';
          // this.paymentTypeDisabled = true;
        }
      }
      if (
        this.screenControls.filter((m) => m.code === 'showpriceonaddservice')
          .length > 0
      ) {
        this.showPriceOnService = this.screenControls.filter(
          (m) => m.code === 'showpriceonaddservice',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'changetitleofprefdoctor')
          .length > 0
      ) {
        this.changeTitleOfPrefDoctor = this.screenControls.filter(
          (m) => m.code === 'changetitleofprefdoctor',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'adddefaultotherservice')
          .length > 0
      ) {
        this.addDefaultOtherService = this.screenControls.filter(
          (m) => m.code === 'adddefaultotherservice',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showconsultant').length >
        0
      ) {
        this.showConsultantCard = this.screenControls.filter(
          (m) => m.code === 'showconsultant',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showservice').length > 0
      ) {
        this.showServiceCard = this.screenControls.filter(
          (m) => m.code === 'showservice',
        )[0].is_show;
      }

    }
    if (this.patient) {
      if (this.patient.admitted === true) {
        this.isAdmitted = true;
        this.consultation_services = [];
        this.paymentStatus = 'unpaid';
        this.serviceForm.mode_of_payment = 'Credit';
        this.paymentTypeDisabled = false;
      }
      if (!this.showConsultantCard) {
        this.consultation_services = [];
      }
      if (!this.showServiceCard) {
        this.patient_services = [];
      }
      if (this.patient.patient_type) {
        this.patientTypeCredit = this.patient.patient_type.is_credit;
        if (this.patient.patient_type.is_credit === true) {
          this.serviceForm.mode_of_payment = 'Credit';
          this.paymentStatus = 'unpaid';
          this.paymentTypeDisabled = true;
        }
      }
      this.getTaxesOfPaymentMode(this.serviceForm.mode_of_payment);
      // if (this.patient.admitted === true) {
      //   this.hideUnPaid = false;
      // }
      // if (this.patient.admitted === false) {
      //   this.hideUnPaid = true;
      // }
    }
    if (this.addDefaultOtherService === true) {
      this.patient_services = [];
    }
  }

  fetchPatient(): any {
    // if (this.route.snapshot.queryParams.patient_id) {
    //   this.PatientSearchForm.value.search_query =
    //     this.route.snapshot.queryParams.patient_id;
    // }
    // this.PatientSearchForm.value.type = 'mrn';
    this.ngxLoader.start();
    this.patientService
      .searchPatientByRegTypeV2(this.PatientSearchForm.value)
      .subscribe(
        (data) => {
          this.patient = data.patients[0];
          this.anyContraindicationsToReceiveContrast =
            this.patient.any_contraindications_To_receive_contrast;
          this.anyAllergiesToIodinatedContrast =
            this.patient.any_allergies_to_iodinated_contrast;
          this.anyContraindicationsToReceiveContrastTextarea =
            this.patient.any_contraindications_to_receive_contrast_textarea;
          // if (this.patient.user_info) {
          //   this.userInfo = this.patient.user_info;
          // } else {
          const birthDate = `${this.patient?.birth_year}-${this.patient?.birth_month}-${this.patient?.birth_day}`;
          const formattedAge = this.agePipe.transform(birthDate);
          this.userInfo =
            formattedAge +
              ', ' +
              this.patient?.gender.charAt(0).toUpperCase() +
              this.patient?.gender.slice(1) +
              ' with ';
          // }
          this.serviceForm.patient_id = this.patient.id;
          this.ngxLoader.stop();
          this.updatePaymentTypeConfig();
          this.fetchPartialServices();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchServicesTypes(): any {
    this.ngxLoader.start();
    this.uniq_services = [];
    this.systemControlsService.getServicesTypeConfigV2().subscribe(
      (data) => {
        this.uniq_services = data.service_type_configurators;
        const defaultType = this.uniq_services.find((t) => t.is_default === true);
        if (defaultType) {
          this.current_service_type = defaultType.name;
        } else {
          this.current_service_type = '';
        }
        if (this.patient_services.length > 0) {
          if (this.current_service_type) {
            this.patient_services[0].service_type = this.current_service_type;
            // this.fetchServices(this.current_service_type, 0);
            this.radiologyCheck(this.current_service_type);
            this.fetchSubDepartments(this.current_service_type, 0, '', false);
          }
        }
        this.uniq_services.forEach((type) => {
          this.allServices[type.name] = [];
        });
        if (this.current_service_type) {
          this.defaultServiceType = this.current_service_type;
          this.fetchServicesMain(this.current_service_type);
        }

        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  fetchPartialServices(): any {
    this.ngxLoader.start();
    this.partialServices = [];
    this.systemControlsService.getPartialServices(this.patient?.id).subscribe(
      (data) => {
        this.partialServices = data?.partial_payment_details;
        if(this.partialServices.length){
          this.partialSerDuePayment = 0;
          for(let i = 0; i < this.partialServices.length; i++){
            this.partialSerDuePayment += this.partialServices[i].total_due_payment || 0;
          }
        }
        console.log('partialServices', this.partialServices);
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.partial_services').selectpicker('refresh');
          $('.partial_services').selectpicker();
        }, 200);
        // setTimeout(() => {
        //
        //   $('#partial_services').selectpicker('refresh');
        //   $('#partial_services').selectpicker();
        // }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  // resetServicesByDep(i): any{
  //   this.fetchSelectedServices(i);
  // }
  fetchServices(type, i): any {
    if (this.allServices[type].length > 0) {
      this.fetchSelectedServices(i, true);
    } else {
      this.ngxLoader.start();
      this.serviceService.getServiceByTypesV2(type).subscribe(
        (data) => {
          this.allServices[type] = data.services;
          this.fetchSelectedServices(i, true);
          this.ngxLoader.stop();
        },
        (err) => {
          this.fetchSelectedServices(i, true);
          this.ngxLoader.stop();
        },
      );
    }
  }

  fetchSubDepartmentsByMain(event, index): any {
    this.serviceType = event;
    // this.subDepartmentsAgainstServiceType[index] = [];
    this.ngxLoader.start();
    this.patientService.getSubDepartmentsAgainstServiceType(event).subscribe(
      (data) => {
        for (let i = index; i < this.patient_services.length; i++) {
          this.subDepartmentsAgainstServiceType[i] = data;
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchSubDepartments(event, index, serviceObj, value): any {
    this.serviceType = event;
    this.subDepartmentsAgainstServiceType[index] = [];
    this.ngxLoader.start();
    this.patientService.getSubDepartmentsAgainstServiceType(event).subscribe(
      (data) => {
        this.subDepartmentsAgainstServiceType[index] = data;
        if (value) {
          if (serviceObj.department_id) {
            this.patient_services[index].service_dept = '';
            this.patient_services[index].department_id = serviceObj.department_id;
          }
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  radiologyCheck(event): any {
    if (this.changeTitleOfPrefDoctor) {
      this.changeTextToRequestedDoctor = event === 'Radiology' ? true : false;
    }
    const checkValue = this.patient_services.find(
      (t) => t.service_type === 'Radiology',
    );
    this.showElecgiesFields =
      checkValue && Object.keys(checkValue).length ? true : false;
  }

  fetchServicesMain(type): any {
    if (this.allServices[type].length > 0) {
      $('#serviceNameMain').val('');
      setTimeout(() => {
        $('#serviceNameMain').selectpicker('refresh');
      }, 500);
    } else {
      this.ngxLoader.start();
      this.serviceService.getServiceByTypesV2(type).subscribe(
        (data) => {
          this.allServices[type] = data.services;
          $('#serviceNameMain').val('');
          if (
            this.patient_services.length === 1 &&
            this.patient_services[0].service_id === ''
          ) {
            $('#serviceName0').val('');
          }
          setTimeout(() => {
            $('#serviceNameMain').selectpicker('refresh');
            if (
              this.patient_services.length === 1 &&
              this.patient_services[0].service_id === ''
            ) {
              $('#serviceName0').selectpicker('refresh');
            }
          }, 500);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
    }
  }

  addOtherServicesMain() {
    this.disableAddServiceMainBtn = true;
    // if (this.patient_services.length === 0) {
    //   this.addMoreServices();
    // }
    setTimeout(() => {
      let ids = [];
      if (this.requestedServices) {
        ids = $('#serviceRequested').val();
      } else {
        ids = $('#serviceNameMain').val();
      }
      if (
        !(
          this.patient_services.length === 1 &&
          $('#serviceName' + 0).val() === ''
        )
      ) {
        if ($('#serviceName' + 0).val() === '-1') {
          this.removeServices(0);
        }
      }
      if (this.requestedServices === true) {
        for (const id of ids) {
          if (id !== '') {
            let serviceId;
            serviceId = this.requestedServicesList.find(
              (m) => m.id === Number(id),
            );
            if (
              this.patient_services.find(
                (m) => m.patient_service_id === serviceId.patient_service_id,
              )
            ) {
              toastr.error('Already Added');
              this.disableAddServiceMainBtn = false;
              return;
            }
          }
        }
      }
      const startIndex = this.patient_services.length;
      ids.forEach((id) => {
        if (id !== '') {
          this.addMoreServices();
        }
      });
      this.fetchServicesDeptMain(ids, this.requestedServices, startIndex);
      if (this.requestedServices === false) {
        this.fetchSubDepartmentsByMain(this.defaultServiceType, startIndex);
      }
      this.disableAddServiceMainBtn = false;
    }, 20);
  }

  fetchServicesDeptMain(ids, requested, startIndex): any {
    const billingCodes = [];
    ids.forEach((value, valueIndex) => {
      const ind = startIndex + valueIndex;
      if (value !== '') {
        if (requested === true) {
          let service;
          service = this.requestedServicesList.find(
            (m) => m.id === Number(value),
          );
          this.patient_services[ind].comments = service.comments || '' +
            '';
          this.patient_services[ind].commentField = service.comments ? true : false;
          this.patient_services[ind].service_type = service.assignable_type;
          this.patient_services[ind].requested_service_type = service.requested_service_type;
          this.patient_services[ind].requested_service_id = service.requested_service_id;
          this.patient_services[ind].new_requested_service = service.new_requested_service;
          this.allServices[service.assignable_type].push(service);
          let refId = service.referring_doctor_id;
          if (refId) {
            refId = service.referring_doctor_id + '/' + service.employee_type;
          }
          this.patient_services[ind].referring_doctor_id = refId || '';
          this.updateReferringDoctor(ind);
          this.patient_services[ind].performing_doctor_id =
            service.performing_doctor_id || '';
          this.patient_services[ind].urgent_new = service.urgent_new;
          this.patient_services[ind].quantity = service.quantity || 1;
        } else {
          this.patient_services[ind].service_type = this.defaultServiceType;
          this.patient_services[ind].quantity = 1;
        }
      }
      const serviceType = this.patient_services[ind].service_type;
      // const serviceMap = new Map(this.allServices[serviceType].map(m => [m.id.toString(), m]));
      // const serviceObj = serviceMap.get(value);
      const serviceObj = this.allServices[serviceType].find(
        (m) => m.id.toString() === value,
      );
      this.fetchSubDepartments(serviceType, valueIndex, serviceObj, true);
      this.patient_services[ind].billing_code = serviceObj.billing_code;
      this.patient_services[ind].service_id = serviceObj.id;
      this.patient_services[ind].patient_service_id =
        serviceObj.patient_service_id;
      this.patient_services[ind].reporting_time_required =
        serviceObj.reporting_time;
      this.patient_services[ind].price = 0;
      this.patient_services[ind].actual_price = 0;
      this.patient_services[ind].service_price = 0;
      this.patient_services[ind].discount = 0;
      this.patient_services[ind].discount_per = 0;
      this.patient_services[ind].quantity_allowed = false;
      this.patient_services[ind].editable = false;
      this.patient_services[ind].installment_enabled = false;
      this.patient_services[ind].pricing_id = '';
      billingCodes.push(serviceObj.billing_code);
      let partialServices: any = null;
      partialServices  = this.partialServices.find(t => t?.service_id === Number(serviceObj.id));
      if (partialServices) {
       toastr.error(`Payment is already due against "${partialServices.service_name}" service`);
       this.patient_services[ind].service_price = partialServices.total_due_payment;
       this.patient_services[ind].price = partialServices.total_due_payment * (this.patient_services[ind].quantity * 1);
       this.patient_services[ind].actual_price = partialServices.total_due_payment;
       const discount =
        (partialServices.total_due_payment / 100) * 0;
       this.patient_services[ind].discount = Math.round(discount);
       this.patient_services[ind].pricing_id = partialServices.pricing_id;
       this.patient_services[ind].patient_partial_payment_ids = partialServices?.patient_partial_payment_ids || [];
       this.patient_services[ind].quantity_allowed = false;
       if (this.currentHospital !== 'uolHospital') {
        this.patient_services[ind].editable = false;
      }
       this.patient_services[ind].installment_enabled = true;
       this.patient_services[ind].discount_per = 0;
       this.updatePrice();
    } else {
      this.fetchServicePrice(billingCodes, startIndex);
    }
      setTimeout(() => {
        $('#serviceName' + ind).val(serviceObj.id);
        $('#performingDoctor' + ind).val(
          this.patient_services[ind].performing_doctor_id,
        );
        $('#performingDoctor' + ind).selectpicker('refresh');
        $('#referringDoctor' + ind).val(
          this.patient_services[ind].referring_doctor_id,
        );
        $('#referringDoctor' + ind).selectpicker('refresh');
        $('#serviceName' + ind).selectpicker('refresh');
        this.chRef.detectChanges();
      }, 100);
    });
    // this.fetchServicePrice(billingCodes, startIndex);
    this.radiologyCheck(this.defaultServiceType);
    $('#serviceNameMain').val('');
    $('#serviceNameMain').selectpicker('refresh');
    this.chRef.detectChanges();
  }

  fetchDoctors(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchOpdConsultationDoctorV2().subscribe(
      (data) => {
        this.doctorsAll = data.users;
        this.doctors[0] = data.users;
        // this.consultation_services[0].doctor_id = this.doctorsAll[0].id;
        // this.consultation_services[0].department_id = this.doctorsAll[0].doctor_department_id.toString();
        if (this.consultation_services.length > 0) {
          this.consultation_services[0].doctor_id = '';
          // this.consultation_services[0].department_id = '';
          // this.consultation_services[0].sub_department_id = '';
          // this.consultation_services[0].clinic_name = this.patient.clinic_name;
        }
        this.ngxLoader.stop();
        // $('#referringDoctor0').selectpicker('refresh');
        // $('#performingDoctor0').selectpicker('refresh');
        setTimeout(() => {
          $('.referringDoctors').selectpicker();
          $('.referringDoctors').selectpicker('refresh');
          $('.performingDoctors').selectpicker();
          $('.performingDoctors').selectpicker('refresh');
          $('#performingDoctor0').selectpicker();
          $('.doctorSelectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getPatient(patient): any{
    if (patient){
    this.resetForm(patient, true, false);
  }
  }

  fetchLookup(index): any {
    this.ngxLoader.start();
    this.lookupService
      .indexVisitTypeV2(
        this.consultation_services[index].doctor_id,
        this.consultation_services[index].department_id,
        true,
        '',
        '',
      )
      .subscribe(
        (data) => {
          // this.lookups = data.lookups;
          this.visitType[index] = data.lookups;
          this.consultation_services[index].service_type =
            this.visitType[index].filter(
              (t) => t.key === 'Normal' || t.key === 'General',
            )[0]?.id || '';
          if (this.consultation_services[index].service_type) {
            this.fetchVisitService(
              this.consultation_services[index].service_type,
              index,
            );
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  leadersAccount(): any {
    this.ngxLoader.start();
    this.hospitalService.getLedgersV2().subscribe(
      (data) => {
        this.ledgersList = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.getOpdDepartmentsListV2().subscribe(
      (data) => {
        this.departments = data.departments;
        // this.consultation_services[0].department_id = this.departments[0].id;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.departmentSelectpicker').selectpicker();
        }, 1000);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDoctorDepartments(id, index): any {
    const objIdDepart = '#conDept' + index;
    this.doctor_id = id;
    setTimeout(() => {
      $(objIdDepart).selectpicker('refresh');
    }, 100);
    this.consultation_services[index].service_id = '';
    this.consultation_services[index].billing_code = '';
    this.consultation_services[index].pricing_id = '';
    this.consultation_services[index].price = 0;
    this.consultation_services[index].discount = 0;
    this.consultation_services[index].discount_per = 0;
    this.consultation_services[index].service_price = 0;
    this.consultation_services[index].actual_price = 0;
    this.consultation_services[index].quantity = 0;
    this.consultation_services[index].sub_department_id = '';
    this.consultation_services[index].clinic_name = this.patient?.clinic_name;
    this.consultation_services[index].service_type = '';
    const doctorDetail = this.doctors[index].find((t) => t.id === Number(id));
    this.doctorRoomNumber = doctorDetail.room_number;
    this.consultation_services[index].department_id =
      doctorDetail.doctor_department_id;
    this.getSubDepartment(
      this.consultation_services[index].department_id,
      index,
    );
    setTimeout(() => {
      $(objIdDepart).selectpicker('refresh');
    }, 100);

    setTimeout(() => {
      $(objIdDepart).selectpicker();
    }, 100);
    this.fetchLookup(index);
    // this.fetchVisitService(this.consultation_services[index].service_type, index);
    this.updatePrice();
  }

  fetchDeptDoctors(id, index): any {
    // this.fetchVisitService(this.consultation_services[index].service_type, index);
    this.consultation_services[index].service_id = '';
    this.consultation_services[index].billing_code = '';
    this.consultation_services[index].pricing_id = '';
    this.consultation_services[index].price = 0;
    this.consultation_services[index].discount = 0;
    this.consultation_services[index].discount_per = 0;
    this.consultation_services[index].service_type = '';
    this.consultation_services[index].doctor_id = '';
    this.consultation_services[index].sub_department_id = '';
    this.consultation_services[index].clinic_name = this.patient?.clinic_name;
    this.doctors[index] = [];
    this.subDepartments[index] = [];
    const objId = '#conDoctor' + index;
    this.getSubDepartment(id, index);
    this.fetchLookup(index);
    setTimeout(() => {
      $(objId).selectpicker('refresh');
    }, 500);
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorListV2(id).subscribe(
      (data) => {
        this.doctors[index] = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $(objId).selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $(objId).selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
    this.updatePrice();
  }

  getSubDepartment(id, index): any {
    const objIdSubDepart = '#conSubDept' + index;
    this.departmentService.parent_deptV2(id).subscribe(
      (data) => {
        this.subDepartments[index] = data.departments;
        if (this.subDepartments[index]) {
          const defaultSubDepart = this.subDepartments[index].find(t => t.is_default === true)?.id;
          if (defaultSubDepart) {
            this.consultation_services[index].sub_department_id = defaultSubDepart;
          } else{
            this.consultation_services[index].sub_department_id =
              data.departments[0].id;
          }
        }
        setTimeout(() => {
          $(objIdSubDepart).selectpicker('refresh');
        }, 500);
      },
      (err) => {
        $(objIdSubDepart).selectpicker('refresh');
        toastr.error(err.error.message);
      },
    );
  }

  fetchVisitService(id, index): any {
    if (id === '' || id === null || id === undefined) {
      return;
    }
    $('.conService').css('border-color', '#ccc');
    // if (this.consultation_services[index].doctor_id === '') {
    //   this._flashMessagesService.show('Select doctor first', {cssClass: 'alert-danger', timeout: 5000});
    //   return;
    // }
    this.ngxLoader.start();
    this.serviceService
      .getVisitServiceV3(
        this.patient?.id,
        id,
        this.consultation_services[index].doctor_id,
        this.consultation_services[index].department_id,
        'true',
        'false',
        'false',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data?.service?.id) {
            this.consultation_services[index].service_id = data?.service.id;
            this.consultation_services[index].billing_code =
              data.service.billing_code;
            this.fetchConsultationPrice(index);
          } else {
            this.zeroConsultationPrice(index);
          }
        },
        (err) => {
          this.zeroConsultationPrice(index);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  zeroConsultationPrice(index): any {
    this.consultation_services[index].service_id = '';
    this.consultation_services[index].billing_code = '';
    this.consultation_services[index].pricing_id = '';
    this.consultation_services[index].price = 0;
    this.consultation_services[index].discount = 0;
    this.consultation_services[index].discount_per = 0;
    this.consultation_services[index].service_price = 0;
    this.consultation_services[index].actual_price = 0;
    this.consultation_services[index].quantity = 0;
    setTimeout(() => {
      $('.doctorSelectpicker').selectpicker();
      $('.doctorSelectpicker').selectpicker('refresh');
    }, 500);
    this.updatePrice();
  }

  fetchConsultationPrice(index): any {
    const obj = {
      patient_type_id: this.patient.patient_type.id,
      current_billing_code: this.consultation_services[index].billing_code,
    };
    this.ngxLoader.start();
    this.pricingService.searchPriceV3(obj).subscribe(
      (data) => {
        let discount;
        if (
          this.patient.admissions.length > 0 &&
          this.patient.admitted === true
        ) {
          this.consultation_services[index].price = data.pricings[0].ipd_price;
          this.consultation_services[index].service_price =
            data.pricings[0].ipd_price;
          this.consultation_services[index].actual_price =
            data.pricings[0].ipd_price;
          discount =
            (data.pricings[0].ipd_price / 100) *
            data.pricings[0].system_discount_percentage;
        } else {
          this.consultation_services[index].price = data.pricings[0].opd_price;
          this.consultation_services[index].service_price =
            data.pricings[0].opd_price;
          this.consultation_services[index].actual_price =
            data.pricings[0].opd_price;
          discount =
            (data.pricings[0].opd_price / 100) *
            data.pricings[0].system_discount_percentage;
        }
        this.consultation_services[index].discount = Math.round(discount);
        this.consultation_services[index].pricing_id = data.pricings[0].id;
        // this.consultation_services[index].price = pricing;
        // this.consultation_services[index].service_price = pricing;
        this.consultation_services[index].quantity = 1;
        this.ngxLoader.stop();
        // For Default consultant Price Zero not to Add in services

        // if (Number(this.consultation_services[index].service_price) === 0) {
        //   this.removeConsultant(index);
        //   this.addConsultant();
        //   if(this.current_service_type) {
        //     this.fetchServices(this.current_service_type, index);
        //     toastr.error('This service is not configured. Please contact IT Administrator.');
        //
        //   }
        //   setTimeout(()=>{
        //     $('.doctorSelectpicker').selectpicker();
        //     $('.doctorSelectpicker').selectpicker('refresh');
        //   },500);
        // }

        this.updatePrice();
      },
      (err) => {
        this.ngxLoader.stop();
        this.consultation_services[index].price = 0;
        toastr.error(err.error.message);
      },
    );
  }

  addConsultant(): any {
    const obj = {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      comments: '',
      department_id: '',
      sub_department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      price: 0,
      discount: 0,
      discount_per: 0,
      service_price: 0,
      actual_price: 0,
      quantity: 0,
      urgent_new: '',
      quantity_allowed: false,
      editable: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
      clinic_name: this.patient?.clinic_name,
    };
    this.consultation_services.push(obj);
    const length = this.consultation_services.length;

    this.doctors[length - 1] = this.doctorsAll;

    // this.consultation_services[length - 1].doctor_id = this.doctorsAll[0].id;
    // this.consultation_services[length - 1].department_id = this.doctorsAll[0].doctor_department_id.toString();

    setTimeout(() => {
      const ind = length - 1;
      $('#conDoctor' + ind).selectpicker();
    }, 500);

    // this.consultation_services[length - 1].service_type =
    //   this.lookups.filter(
    //     (t) =>
    //       t.category === 'Visit Type' &&
    //       (t.key === 'Normal' || t.key === 'General'),
    //   )[0].id || '';
  }

  removeConsultant(index): any {
    // if ( this.consultation_services.length === 1 ) {
    //   return;
    // }
    this.consultation_services.splice(index, 1);
    this.doctors.splice(index, 1);
    this.subDepartments.splice(index, 1);
    this.updatePrice();
  }

  updatePrice(): any {
    this.consultantionTotal = 0;
    this.servicesTotal = 0;
    this.serviceForm.discount = 0;
    this.serviceForm.discount_percentage = 0;
    for (let i = 0; i < this.consultation_services.length; i++) {
      this.consultantionTotal =
        this.consultantionTotal + this.consultation_services[i].price;
      this.serviceForm.discount =
        this.serviceForm.discount +
        this.consultation_services[i].quantity *
          this.consultation_services[i].discount;
    }
    for (let i = 0; i < this.patient_services.length; i++) {
      this.servicesTotal = this.servicesTotal + this.patient_services[i].price;
      this.serviceForm.discount =
        this.serviceForm.discount +
        this.patient_services[i].quantity * this.patient_services[i].discount;
    }
    this.serviceForm.cash =
      this.consultantionTotal + this.servicesTotal - this.serviceForm.discount;
    this.addTaxToServicesArray();
    if (this.serviceForm.discount) {
      this.calculateDiscountAmount('amount');
    } else {
      this.updateServiceReceivable();
      this.serviceForm.discount_percentage = 0;
    }
  }

  updateServiceReceivable(): any {
    const totalCash =
      this.consultantionTotal + this.servicesTotal + this.totalTaxValue - this.serviceForm.discount;
    if (this.patientTypeCredit === false) {
      if (this.serviceForm.cash >= totalCash) {
        this.serviceForm.cash = totalCash;
        this.serviceForm.receivable = 0;
        this.serviceForm.ledger = '';
      } else {
        this.serviceForm.receivable = totalCash - this.serviceForm.cash;
      }
    } else {
      // this.serviceForm.cash = 0;
      // this.serviceForm.receivable = totalCash;
      this.serviceForm.ledger = '';
      this.serviceForm.cash = totalCash;
      this.serviceForm.receivable = 0;
      this.serviceForm.mode_of_payment = 'Credit';
      this.paymentStatus = 'unpaid';
    }
    this.chRef.detectChanges();
  }

  // services start ==========
  fetchSelectedServices(ind, resetDepart): any {
    this.patient_services[ind].service_dept = '';
    if (resetDepart) {
      this.patient_services[ind].department_id = '';
    }
    this.patient_services[ind].billing_code = '';
    this.patient_services[ind].comments = '';
    this.patient_services[ind].service_id = '';
    this.patient_services[ind].service_price = 0;
    this.patient_services[ind].actual_price = 0;
    this.patient_services[ind].price = 0;
    this.patient_services[ind].quantity = 0;
    this.patient_services[ind].commentField = false;
    $('#serviceName' + ind).val('-1');
    $('#referringDoctor' + ind).val('');
    $('#performingDoctor' + ind).val('');
    this.updatePrice();
    this.chRef.detectChanges();
    $('#serviceName' + ind).selectpicker('refresh');
    $('#referringDoctor' + ind).selectpicker('refresh');
    $('#performingDoctor' + ind).selectpicker('refresh');
    setTimeout(() => {
      $('#serviceName' + ind).selectpicker();
      $('#referringDoctor' + ind).selectpicker();
      $('#performingDoctor' + ind).selectpicker();
    }, 500);
  }

  updateServicesDept(ind, event): any {
    const serviceType = this.patient_services[ind].service_type;
    const serviceObj = this.allServices[serviceType].find(
      (m) => m.id.toString() === event,
    );
    const subDepartId = this.subDepartmentsAgainstServiceType[ind].find((m) => m.id === serviceObj?.department_id);
    if (serviceObj.department_id && subDepartId) {
      this.patient_services[ind].service_dept = '';
      this.patient_services[ind].department_id = serviceObj?.department_id ?? '';
      setTimeout(() => {
        $('#serviceName' + ind).val(serviceObj.id.toString());
      }, 200);
    } else {
      this.patient_services[ind].department_id = '';
    }
    this.patient_services[ind].billing_code = serviceObj.billing_code;
    this.patient_services[ind].service_id = serviceObj.id;
    this.patient_services[ind].patient_service_id =
      serviceObj.patient_service_id;
    this.patient_services[ind].reporting_time_required =
      serviceObj.reporting_time;
    this.patient_services[ind].price = 0;
    this.patient_services[ind].actual_price = 0;
    this.patient_services[ind].service_price = 0;
    this.patient_services[ind].discount = 0;
    this.patient_services[ind].discount_per = 0;
    this.patient_services[ind].quantity_allowed = false;
    this.patient_services[ind].editable = false;
    this.patient_services[ind].installment_enabled = false;
    this.patient_services[ind].quantity = 1;
    this.patient_services[ind].pricing_id = '';
    const partialServices = this.partialServices.find(t => t?.service_id === Number(event));
    if (partialServices) {
       toastr.error(`Payment is already due against "${partialServices.service_name}" service`);
       this.patient_services[ind].service_price = partialServices.total_due_payment;
       this.patient_services[ind].price = partialServices.total_due_payment * (this.patient_services[ind].quantity * 1);
       this.patient_services[ind].actual_price = partialServices.total_due_payment;
       const discount =
        (partialServices.total_due_payment / 100) * 0;
       this.patient_services[ind].discount = Math.round(discount);
       this.patient_services[ind].pricing_id = partialServices.pricing_id;
       this.patient_services[ind].patient_partial_payment_ids = partialServices?.patient_partial_payment_ids || [];
       this.patient_services[ind].quantity_allowed = false;
       if (this.currentHospital !== 'uolHospital') {
        this.patient_services[ind].editable = false;
      }
       this.patient_services[ind].installment_enabled = true;
       this.patient_services[ind].discount_per = 0;
       this.updatePrice();
    } else {
      this.fetchServicePrice([serviceObj.billing_code], ind);
    }
    setTimeout(() => {
      $('#serviceName' + ind).selectpicker('refresh');
      this.chRef.detectChanges();
      setTimeout(() => {
        $('#serviceName' + ind).selectpicker();
      }, 200);
    }, 200);
  }

  fetchServicePrice(code, start): any {
    this.ngxLoader.start();
    this.pricingService
      .searchPriceByMultipleServices(this.patient, code)
      .subscribe(
        (data) => {
          data.pricings.forEach((price) => {
            const ind = start;
            if (price.is_configured === false) {
              toastr.error(price.message);
            } else {
              let discount;
              if (
                this.patient.admissions.length > 0 &&
                this.patient.admitted === true
              ) {
                this.patient_services[ind].service_price = price.ipd_price;
                this.patient_services[ind].price = price.ipd_price * (this.patient_services[ind].quantity * 1);
                this.patient_services[ind].actual_price = price.ipd_price;
                discount =
                  (price.ipd_price / 100) * price.system_discount_percentage;
              } else {
                this.patient_services[ind].service_price = price.opd_price;
                this.patient_services[ind].price = price.opd_price * (this.patient_services[ind].quantity * 1);
                this.patient_services[ind].actual_price = price.opd_price;
                discount =
                  (price.opd_price / 100) * price.system_discount_percentage;
              }
              this.patient_services[ind].discount = Math.round(discount);
              this.patient_services[ind].pricing_id = price.id;
              this.patient_services[ind].quantity_allowed =
                price.quantity_allowed || false;
              if (this.currentHospital !== 'uolHospital') {
                this.patient_services[ind].editable = price.editable || false;
              }
              this.patient_services[ind].installment_enabled = price.installment_enabled || false;
              this.patient_services[ind].discount_per = price.system_discount_percentage;
            }
            start++;
          });
          this.ngxLoader.stop();
          this.updatePrice();
        },
        (err) => {
          this.updatePrice();
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  removeServices(i): any {
    // if ( this.patient_services.length > 1 ) {
    this.patient_services.splice(i, 1);
    // }
    this.updatePrice();
  }

  // create a function and apply  a condition to stop calling the Apis and other function Again and Again
  moreServices(): any {
    if (this.current_service_type) {
      this.callDefaultServiceArray = true;
    }
    this.addMoreServices();
  }

  addMoreServices(): any {
    const obj = {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: this.current_service_type || '',
      sub_department: '',
      service_dept: '',
      comments: '',
      department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      mode_of_payment: '',
      quantity: 0,
      service_price: 0,
      actual_price: 0,
      price: 0,
      discount: 0,
      discount_per: 0,
      urgent_new: 'routine',
      quantity_allowed: false,
      editable: false,
      installment_enabled: false,
      patient_service_id: '',
      flight_date_time: '',
      reporting_time_required: false,
      employee_type: '',
      commentField: false,
      requested_service_type: '',
      requested_service_id: '',
      new_requested_service: false,
      patient_partial_payment_ids: [],
    };
    this.patient_services.push(obj);
    const ind = this.patient_services.length - 1;
    console.log('this.subDepartmentsAgainstServiceType[ind] Before:', this.subDepartmentsAgainstServiceType[ind]);
    this.subDepartmentsAgainstServiceType[ind] = [];
    console.log('this.subDepartmentsAgainstServiceType[ind] After:', this.subDepartmentsAgainstServiceType[ind]);
    if (this.callDefaultServiceArray) {
      this.fetchServices(this.current_service_type, ind);
      this.radiologyCheck(this.current_service_type);
      this.fetchSubDepartments(this.current_service_type, ind, '', false);
      this.callDefaultServiceArray = false;
    }
    // this.services[ind] = [];
    $('#serviceName' + ind).selectpicker('refresh');
    $('#referringDoctor' + ind).selectpicker('refresh');
    $('#performingDoctor' + ind).selectpicker('refresh');
    setTimeout(() => {
      $('#serviceName' + ind).selectpicker();
      $('#referringDoctor' + ind).selectpicker();
      $('#performingDoctor' + ind).selectpicker();
    }, 50);
  }

  paymentMethods(method): any {
    // if (event.target.checked) {
    this.serviceForm.mode_of_payment = method;
    // } else {
    // event.target.checked = true;
    // }
    this.serviceForm.payment_details = '';
    this.onChangeServicePaymentMode();
  }
  getTaxesOfPaymentMode(paymentMode): any{
    this.ngxLoader.start();
    this.patientService.getTaxesOfPaymentMode(paymentMode).subscribe(data => {
      this.taxesData = data;
      this.addTaxToServicesArray();
      this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
      toastr.error(err.error.message);
    });
  }
  addTaxToServicesArray(): any{
    const serviceNetAmt = this.consultantionTotal +
      this.servicesTotal -
      this.serviceForm.discount;
    this.totalTaxValue = 0;
    for (let j = 0; j < this.taxesData.length; j++) {
      if (this.taxesData[j].value_type === 'Percentage') {
        this.taxesData[j].totalTax = Math.ceil((serviceNetAmt * this.taxesData[j].value) / 100);
      } else {
        this.taxesData[j].totalTax = this.taxesData[j].value;
      }
      this.totalTaxValue += this.taxesData[j].totalTax;
    }
    this.serviceForm.cash += this.totalTaxValue;
  }
  paymentMethod(method, event): any {
    if (event.target.checked) {
      this.serviceForm.mode_of_payment = method;
    } else {
      event.target.checked = true;
    }
    this.serviceForm.payment_details = '';
  }

  updatePaymentStatus(): any {
    this.paymentTypeDisabled = false;
    if (this.paymentStatus === 'unpaid') {
      this.serviceForm.mode_of_payment = 'Credit';
      // this.paymentTypeDisabled = true;
    } else {
      this.serviceForm.mode_of_payment = 'Cash';
    }
    this.getTaxesOfPaymentMode(this.serviceForm.mode_of_payment);
    this.updatePrice();
  }

  addServiceConfirm(): any {
    if (this.modalRef !== null) {
      return;
    }
    let count = 0;
    $('.conService').css('border-color', '#ccc');
    $('.conDoctorMain').css('border-color', '#ccc');
    $('.conDeptMain').css('border-color', '#ccc');
    $('.serviceType').css('border-color', '#ccc');
    $('.serviceName').css('border-color', '#ccc');
    $('.serviceQuantity').css('border-color', '#ccc');
    $('div').removeClass('border-alert');
    const taxes = [];
    for (let j = 0; j < this.taxesData.length; j++) {
      const taxesObj = {
        id: this.taxesData[j].id,
        service: this.taxesData[j]?.service ?? '',
        name: this.taxesData[j].name,
        value: this.taxesData[j].value,
        value_type: this.taxesData[j].value_type,
        tax_amount: this.taxesData[j].totalTax,
        is_tax_adjustable: this.taxesData[j]?.is_tax_adjustable,
      };
      taxes.push(taxesObj);
    }
    const consultantion = this.consultation_services;
    const patientServices = this.patient_services;
    this.serviceForm.taxes = taxes;
    for (let i = 0; i < consultantion.length; i++) {
      if (consultantion[i].service_type === '') {
        count++;
        // $('#conService' + i).css({'border':'1px solid red','border-radius':'5px'});
        $('#conService' + i).css('border-color', 'red');
      } else {
        // @ts-ignore
        consultantion[i].service_type = Number(consultantion[i].service_type);
      }
      if (!this.allowVisitZeroPrice) {
        if (consultantion[i].price === 0) {
          count++;
          $('#conPrice' + i).css('border-color', 'red');
        }
      }
      // if (consultantion[i].doctor_id === '') {
      //   count++;
      //   $('#conDoctor' + i).css({'border':'1px solid red','border-radius':'5px'});
      // }
      if (consultantion[i].department_id === '') {
        count++;
        $('#conDeptMain' + i).css({
          border: '1px solid red',
          'border-radius': '5px',
        });
      }
      if (consultantion[i].sub_department_id === '') {
        count++;
        $('#conSubDept' + i).css({
          border: '1px solid red',
          'border-radius': '5px',
        });
      }
    }

    for (let i = 0; i < patientServices.length; i++) {
      if (
        patientServices[i].department_id === null ||
        patientServices[i].department_id === undefined ||
        patientServices[i].department_id === ''
      ) {
        count++;
        $('#subDepart' + i).css('border-color', 'red');
      }
      if (
        patientServices[i].quantity === null ||
        patientServices[i].quantity === undefined ||
        patientServices[i].quantity === 0
      ) {
        count++;
        $('#serviceQuantity' + i).css('border-color', 'red');
      }
      if (!this.allowServiceZeroPrice) {
        if (this.patient_services[i].price === 0) {
          count++;
          $('#serviceprice' + i).css('border-color', 'red');
        }
      }
      if (patientServices[i].service_id === '') {
        count++;
        $('#serviceName' + i).css('border-color', 'red');
      }
      if (patientServices[i].service_id === '') {
        count++;
        $('#serviceName' + i).css('border-color', 'red');
      }
      if (patientServices[i].service_type === '') {
        count++;
        $('#serviceType' + i).css('border-color', 'red');
      } else {
        const typeObj = this.uniq_services.find(
          (m) => m.name === patientServices[i].service_type,
        );
        if (
          typeObj.ref_doctor === true &&
          patientServices[i].referring_doctor_id === ''
        ) {
          count++;
          $('.referringDoctor' + i).addClass('border-alert');
        }
        if (
          typeObj.perf_doctor === true &&
          patientServices[i].performing_doctor_id === ''
        ) {
          count++;
          $('.performingDoctor' + i).addClass('border-alert');
        }
        if (typeObj.price === 0) {
          count++;
          $('.performingDoctor' + i).addClass('border-alert');
        }
      }
    }
    if (count > 0) {
      return;
    }
    count = 0;
    for (let i = 0; i < patientServices.length; i++) {
      if (patientServices[i].pricing_id === '') {
        count++;
        $('#serviceName' + i).css('border-color', 'red');
        toastr.error('Price not exist');
      }
    }
    if (count > 0) {
      return;
    }
    if (
      this.checkDuplicateServices(
        patientServices,
        this.blockedDuplicateServices,
      )
    ) {
      toastr.error('Duplicate services selected');
      return;
    }

    this.serviceForm.patient_services = $.merge(
      $.merge([], consultantion),
      patientServices,
    );
    if (this.serviceForm.patient_services.length === 0) {
      toastr.error('Add at least one service');
      return;
    }
    if (this.patientTypeCredit === false) {
      if (this.serviceForm.receivable > 0 && this.serviceForm.ledger === '') {
        toastr.error('Select ledger');
        return;
      }
    }
    if (this.serviceForm.cash === null || this.serviceForm.cash === undefined) {
      this.serviceForm.cash = 0;
    }
    if (
      this.serviceForm.discount === null ||
      this.serviceForm.discount === undefined ||
      this.serviceForm.discount < 0
    ) {
      $('.discount_amount').addClass('border-alert');
      return;
    }
    if (
      this.serviceForm.discount_percentage === null ||
      this.serviceForm.discount_percentage === undefined ||
      this.serviceForm.discount_percentage < 0 ||
      this.serviceForm.discount_percentage > 100
    ) {
      $('.discount_percentage').addClass('border-alert');
      return;
    }
    if (this.serviceForm.mode_of_payment === 'Bank Transfer'){
      if (!this.serviceForm.bank_transfer){
        toastr.error('Select Bank');
        return;
      }
    }
    patientServices.forEach((service) => {
      delete service.commentField;
    });
    // if (this.hasDuplicateIds(this.serviceForm.patient_services)) {
    //   toastr.error('Duplicate services are selected');
    //   return;
    // }
    this.isChecked = false;
    if (this.isSubmitted === false) {
      this.modalRef = this.modalService.open(this.serviceAddConfirm, { size: 'lg' });
      this.modalRef.result.finally(() => {
        this.modalRef = null;
      });
    }
  }

  hasDuplicateIds(patient_services): any {
    return (
      patient_services.filter(
        (service, index, arr) =>
          arr.findIndex((s) => s.service_id === service.service_id) !== index,
      ).length > 0
    );
  }

  checkDuplicateServices(data, types): any {
    const serviceIdsMap = {};

    // Initialize sets for each service type
    types.forEach((type) => {
      serviceIdsMap[type] = new Set();
    });

    // Iterate through the data array
    for (const item of data) {
      // Check if the item's service_type is in the provided types
      if (types.includes(item.service_type)) {
        const serviceIds = serviceIdsMap[item.service_type];
        // Check for duplicates
        if (serviceIds.has(item.service_id)) {
          return true;
        } else {
          // Add services_id to the set
          serviceIds.add(item.service_id);
        }
      }
    }

    return false;
  }

  addService(): any {
    // if (this.isChecked === false) {
    //  toastr.error('Please give confirmation');
    //  return;
    // }
    this.modalService.dismissAll(this.serviceAddConfirm);
    this.ngxLoader.start();
    this.isSubmitted = true;
    this.patientService
      .createPatientServiceV2(
        this.serviceForm,
        this.patient,
        this.paymentStatus,
        this.anyContraindicationsToReceiveContrast,
        this.anyAllergiesToIodinatedContrast,
        this.anyContraindicationsToReceiveContrastTextarea,
        this.userInfo,
        'add-service',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          console.log('this.show_service_invoice', this.show_service_invoice);
          if (
          this.show_service_invoice === true
          ) {
            window.open(
              'pdf-reports/patient-services-invoices-v2?invoice_id=' +
                data.patient_services[0].patient_service_invoice_item
                  .patient_service_invoice_id +
                '&visit_id=' +
                data.patient_services[0].visit_id +
                '&mrn=' +
                data.patient_services[0].patient_service_invoice_item.patient
                  ?.mrn +
                '&patient_id=' +
                this.patient.id +
              '&hide_billing_history=' + this.hide_billing_history_invoice_short,
            );
          } else {
            if (
              this.currentControls.patient_services.pos_invoice_python_short
            ) {
              window.open(
                environment.pythonUrl +
                  '/report/generate_service_invoice/' +
                  data.patient_services[0].patient_service_invoice_item
                    .patient_service_invoice_id +
              '/?auth_token=' +
                  localStorage.getItem('auth_token') +
                  '&medical_unit_id=' +
                  localStorage.getItem('current_medical_unit_id') +
                '&hide_billing_history=' + this.hide_billing_history_invoice_short,
              );
            } else {
              window.open(
                'pdf-reports/services-invoices-short?id=' +
                  data.patient_services[0].patient_service_invoice_item
                    .patient_service_invoice_id +
                  '&visit_id=' +
                  data.patient_services[0].visit_id +
                '&hide_billing_history=' + this.hide_billing_history_invoice_short,
              );
            }
          }

          if (this.consultation_services.length > 0) {
            if (
              this.currentControls.patient_services.is_print_prescription ===
              true
            ) {
              if (
                this.currentControls.patient_services.is_prescription === false
              ) {
                window.open(
                  'pdf-reports/prescription-v2?patient_id=' +
                    this.patient.id +
                    '&mrn=' +
                    this.patient.mrn +
                    '&visit_id=' +
                    data.patient_services[0].visit_id,
                );
              } else {
                window.open(
                  environment.rorUrl +
                    '/v4/patients/' +
                    this.patient.id +
                    '/prescription_slip.pdf?auth_token=' +
                    localStorage.getItem('auth_token') +
                    '&medical_unit_id=' +
                    localStorage.getItem('current_medical_unit_id') +
                    '&visit_id=' +
                    data.patient_services[0].visit_id,
                );
              }
            }
          }
          this.isSubmitted = false;
          this.resetForm(this.patient, true, true);
          this.fetchServicesTypes();
          // this.backRoute();
        },
        (err) => {
          this.isSubmitted = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  // getInvoiceControls(): any {
  //   this.userService.getInvoiceControls().subscribe(
  //     (data) => {
  //       this.invoiceControl = data.system_billing_control;
  //       this.ngxLoader.stop();
  //     },
  //     (err) => {
  //       this.ngxLoader.stop();
  //       toastr.error(err.error.message);
  //     },
  //   );
  // }
  getServicesControls(): any{
    this.ngxLoader.start();
    this.userService.getServicesControls('').subscribe(
      (data) => {
        this.hide_billing_history_invoice_short = data?.user_front_desk_control?.front_desk_control?.hide_billing_history_invoice_short;
        this.show_service_invoice = data?.user_front_desk_control?.front_desk_control?.show_service_invoice;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }

  skipNegative(e): any {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode == 8
      )
    ) {
      return false;
    }
  }

  selectAllRecentServices(): any {
    if ($('#recentServiceAll').prop('checked') === true) {
      $('.recentService').prop('checked', true);
    }
    if ($('#recentServiceAll').prop('checked') === false) {
      $('.recentService').prop('checked', false);
    }
  }

  selectSideTabs(id, removeClass, tab): any {
    this.activeTab = tab;
    $('.' + removeClass + ' a').removeClass('activeSideTabs');
    $('#' + id + ' a').addClass('activeSideTabs');
    this.initServices();
  }

  initServices(): any {
    this.servicesTotal = 0;
    this.consultantionTotal = 0;
    this.requestedServicesList = [];
    this.requestedServices = false;
    this.servicePaidForm = {
      patient_id: this.patient.id,
      mode_of_payment: 'Cash',
      payment_details: '',
      invoice_ids: [],
      receivable: 0,
      cash: 0,
      ledger: '',
      receivable_percentage: 0,
    };
    this.serviceForm = {
      patient_id: this.patient.id,
      mode_of_payment: 'Cash',
      payment_details: '',
      bank_transfer: '',
      transaction_id: '',
      patient_services: [],
      taxes: [],
      discount: 0,
      discount_percentage: 0,
      receivable: 0,
      cash: 0,
      ledger: '',
      reference: '',
    };
    this.consultation_services = [
      {
        service_id: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        comments: '',
        department_id: '',
        sub_department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        price: 0,
        discount: 0,
        discount_per: 0,
        service_price: 0,
        actual_price: 0,
        quantity: 0,
        urgent_new: '',
        quantity_allowed: false,
        editable: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
        clinic_name: this.patient?.clinic_name,
      },
    ];
    this.patient_services = [
      {
        service_id: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        sub_department: '',
        service_dept: '',
        comments: '',
        department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        quantity: 0,
        service_price: 0,
        actual_price: 0,
        price: 0,
        discount: 0,
        discount_per: 0,
        urgent_new: 'routine',
        quantity_allowed: false,
        editable: false,
        installment_enabled: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
        employee_type: '',
        commentField: false,
        requested_service_type: '',
        requested_service_id: '',
        new_requested_service: false,
        patient_partial_payment_ids: [],
      },
    ];
    this.paymentStatus = 'paid';
    setTimeout(() => {
      $('.selectpicker').selectpicker({
        dropupAuto: false,
      });
      $('.doctorSelectpicker').selectpicker();
      $('.departmentSelectpicker').selectpicker();
    }, 500);
  }

  backRoute(): any {
    this.ngxLoader.start();
    if (this.route.snapshot.queryParams.patient_from === 'settlement') {
      this.router.navigate(['/sale/final-settlement-v2'], {
        queryParams: { patient_mrn: this.patient.mrn },
      });
    } else {
      this.location.back();
    }
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  updateServiceQuantity(ind): any {
    this.patient_services[ind].price =
      this.patient_services[ind].service_price *
      this.patient_services[ind].quantity;
    this.updatePrice();
  }

  updateServicePrice(ind): any {
    if (this.patient_services[ind].installment_enabled){
      if (this.patient_services[ind].service_price > this.patient_services[ind].actual_price){
        this.patient_services[ind].service_price = this.patient_services[ind].actual_price;
      }
    }
    if ( this.patient_services[ind].discount_per > 100 ){
      this.patient_services[ind].discount_per = 100;
    }
    this.patient_services[ind].discount = Math.round(( this.patient_services[ind].service_price / 100 ) * this.patient_services[ind].discount_per);
    // if (
    //   this.patient_services[ind].service_price <
    //   this.patient_services[ind].discount
    // ) {
    //   this.patient_services[ind].service_price =
    //     this.patient_services[ind].discount;
    // }
    this.patient_services[ind].price =
      this.patient_services[ind].service_price *
      this.patient_services[ind].quantity;
    this.updatePrice();
  }

  selectInvoice(): any {
    setTimeout(() => {
      this.updateInvoicesPrice();
    }, 500);
  }

  select_all_invoices(event): any {
    if (event.target.checked) {
      this.patientServiceHistory.map((invoice) => {
        if (invoice.payment_status === 'unpaid') {
          invoice.selected = true;
        }
      });
      this.updateInvoicesPrice();
    } else {
      this.patientServiceHistory.map((invoice) => {
        if (invoice.payment_status === 'unpaid') {
          invoice.selected = false;
        }
      });
      this.updateInvoicesPrice();
    }
  }

  updateInvoicesPrice(): any {
    this.servicePaidForm.cash = 0;
    this.servicePaidForm.receivable = 0;
    this.servicePaidForm.receivable_percentage = 0;
    this.servicePaidForm.ledger = '';
    this.patientServiceHistory.map((invoice, index) => {
      if (invoice.selected === true) {
        this.servicePaidForm.cash = this.servicePaidForm.cash + invoice.cash;
      } else {
        invoice.cash = this.patientServiceHistoryBackup[index].cash;
        invoice.receivable = this.patientServiceHistoryBackup[index].receivable;
      }
    });
  }

  paymentMethodForInvoices(method, event): any {
    if (event.target.checked) {
      this.servicePaidForm.mode_of_payment = method;
    } else {
      event.target.checked = true;
    }
    this.servicePaidForm.payment_details = '';
  }

  unpaidToPaidServicesConfirm(): any {
    this.servicePaidForm.invoice_ids = [];
    this.patientServiceHistory.map((invoice) => {
      if (invoice.selected === true) {
        const obj = {
          id: invoice.id,
          cash: invoice.cash,
          receivable: invoice.receivable,
          net_amount: invoice.base_price - invoice.approved_discount,
        };
        this.servicePaidForm.invoice_ids.push(obj);
      }
    });
    if (this.servicePaidForm.invoice_ids.length === 0) {
      toastr.error('Select Invoices');
      return;
    }
    if (
      this.servicePaidForm.receivable > 0 &&
      this.servicePaidForm.ledger === ''
    ) {
      toastr.error('Select Ledger');
      return;
    }

    this.isChecked = false;
  }

  servicesTab(): any {
    if (this.consultation_services.length === 0 && this.showConsultantCard) {
      this.addConsultant();
    }

    if (this.patient_services.length === 0) {
      if (this.addDefaultOtherService === false && this.showServiceCard) {
        this.addMoreServices();
      }
    }
  }

  changePerformingType(data): any {
    this.serviceId = data.id;
    this.selectedPerformingID = data.doctor_id;
    this.selectedReferringID = data.referring_doctor_id;
    this.modalService.open(this.updatePerformingModal);
  }

  closePerformingModal(): any {
    this.modalService.dismissAll(this.updatePerformingModal);
  }

  updatePerforming(): any {
    this.ngxLoader.start();
    this.hospitalService
      .updateDoctor(
        this.serviceId,
        this.selectedPerformingID,
        this.selectedReferringID,
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.updatePerformingModal);
          this.ngxLoader.stop();
          toastr.success('Updated Performing Doctor');
        },
        (err) => {
          this.modalService.dismissAll(this.updatePerformingModal);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  changeReferringType(data): any {
    this.serviceId = data.id;
    this.selectedReferringID = data.referring_doctor_id;
    this.selectedPerformingID = data.doctor_id;
    this.modalService.open(this.updateReferringModal);
  }

  closeReferringModal(): any {
    this.modalService.dismissAll(this.updateReferringModal);
  }

  updateReferring(): any {
    this.ngxLoader.start();
    this.hospitalService
      .updateDoctor(
        this.serviceId,
        this.selectedPerformingID,
        this.selectedReferringID,
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.updateReferringModal);
          this.ngxLoader.stop();
          toastr.success('Updated referring Doctor');
        },
        (err) => {
          this.modalService.dismissAll(this.updateReferringModal);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  changeRequestedServices(): any {
    Object.keys(this.allServices).forEach((key) => {
      this.allServices[key] = [];
    });
    this.servicesTotal = 0;
    this.consultantionTotal = 0;
    if (this.patient.admitted === true) {
      this.paymentStatus = 'unpaid';
    } else {
      this.paymentStatus = 'paid';
    }
    this.patient_services = [];
    // this.services = [];
    if (this.requestedServices === false) {
      $('#serviceNameMain').val('');
      setTimeout(() => {
        $('#serviceNameMain').selectpicker('refresh');
      }, 500);
      this.addMoreServices();
    } else {
      this.fetchRequestedServices();
    }
  }

  fetchRequestedServices(): any {
    this.ngxLoader.start();
    this.requestedServicesList = [];
    this.serviceService.getRequestedServiceByMrnV2(this.patient.id).subscribe(
      (data) => {
        this.requestedServicesList = data.services;
        $('#serviceRequested').val('-1');
        setTimeout(() => {
          $('#serviceRequested').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        $('#serviceRequested').val('-1');
        setTimeout(() => {
          $('#serviceRequested').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  preventChangeDate(): any {
    return false;
  }

  setReportingTimeModal(index): any {
    $('#reportingTimeModal').modal('show');
    this.selectedReportingIndex = index;
    setTimeout(() => {
      const today = new Date();
      $('#fromDate').datetimepicker({
        defaultDate: today,
        format: 'DD-MM-YYYY h:mm a',
      });
    }, 1000);
  }

  setReportingTime(): any {
    const date = $('#reportingTime').val();
    if (date !== '') {
      $('#reportingTimeModal').modal('hide');
      this.patient_services[this.selectedReportingIndex].flight_date_time =
        moment(date, 'MM/DD/YYYY h:mm a').format('DD-MM-YYYY h:mm a');
    }
  }

  getStaffType(): any {
    this.configurationService.getStaffType().subscribe(
      (data) => {
        this.staffTypeList = data;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getGenderList(): any {
    this.configuratorsService.fetchConfigurationV2('gender').subscribe(
      (data) => {
        this.genderList = data.configurator_types;
        setTimeout(() => {
          $('#gender').selectpicker('refresh');
          $('#gender').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.message.message);
      },
    );
  }

  getDesignationList(): any {
    this.configuratorsService
      .fetchConfigurationV2('designation')
      .subscribe((data) => {
        this.designationList = data.configurator_types;
        setTimeout(() => {
          $('#designation').selectpicker('refresh');
          $('#designation').selectpicker();
        }, 500);
      });
  }

  getTitleList(): any {
    this.configuratorsService.fetchConfigurationV2('title').subscribe(
      (data) => {
        this.titleList = data.configurator_types;
      },
      (err) => {
        toastr.error(err.message.message);
      },
    );
  }

  addNewReferringDoctor(): any {
    if (this.countries.length === 0) {
      this.getCountryData();
    }
    if (this.allEmployeesStaffTypeDoctor.length === 0) {
      this.getEmployeesDoctorWise();
    }
    if (this.staffTypeList.length === 0) {
      this.getStaffType();
    }
    if (this.genderList.length === 0) {
      this.getGenderList();
    }
    if (this.designationList.length === 0) {
      this.getDesignationList();
    }
    if (!this.titleList.length) {
      this.getTitleList();
    }
    this.isSubmitReferring = false;
    this.employeeVisitForm.reset({
      title: '',
      first_name: '',
      last_name: '',
      gender: '',
      speciality: '',
      nic: '',
      pmdc_registration_no: '',
      primary_contact: '',
      hospital_name: '',
      country: '',
      state: '',
      city: '',
      staff_type: '',
      address: '',
      department: '',
      designation: '',
      status: true,
    });
    $('#referringDoctorModal').modal('show');
    setTimeout(() => {
      if (this.staffTypeList.length) {
        $('#staff_type').selectpicker('refresh');
        $('#staff_type').selectpicker();
      }
      $('#refDepartment').selectpicker('refresh');
      $('#refDepartment').selectpicker();
      if (this.genderList.length) {
        $('#gender').selectpicker('refresh');
        $('#gender').selectpicker();
      }
      if (this.titleList.length) {
        $('#title').selectpicker('refresh');
        $('#title').selectpicker();
      }
      if (this.designationList.length) {
        $('#designation').selectpicker('refresh');
        $('#designation').selectpicker();
      }
    }, 1000);
  }

  onSubmitReferringDoctorAdd(): any {
    this.isSubmitReferring = true;
    if (this.employeeVisitForm.invalid) {
      return;
    }
    // const str = this.employeeVisitForm.get('nic').value;
    // const firstHalf = str.slice(0, 13);
    // const secondHalf = str.slice(13, 14);
    // if ( firstHalf !== '' && secondHalf !== '-' ) {
    //   this.employeeVisitForm.get('nic').setValue(firstHalf + '-' + secondHalf);
    // }
    this.employeeVisitForm.get('status').setValue(true);
    this.ngxLoader.start();
    this.visitService.submitVisitForm(this.employeeVisitForm.value).subscribe(
      (data) => {
        this.ngxLoader.stop();
        toastr.success('Successfully Submitted');
        $('#referringDoctorModal').modal('hide');
        let lastName = '';
        if (data.last_name) {
          lastName = data.last_name;
        }
        const obj = {
          id: data.id,
          full_name: data.first_name + ' ' + lastName || '',
          employee_type: 'visiting',
        };
        this.refDoctorsAll.push(obj);
        setTimeout(() => {
          $('.referringDoctors').selectpicker();
          $('.referringDoctors').selectpicker('refresh');
        }, 1000);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  fetchReferringDoctors(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchRefDoctorsDoctorV2().subscribe(
      (data) => {
        this.refDoctorsAll = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#referringDoctor0').selectpicker();
        }, 1000);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateReferringDoctor(index): any {
    setTimeout(() => {
      const data = this.patient_services[index].referring_doctor_id;
      const dataValues = data.split('/');
      this.patient_services[index].referring_doctor_id = dataValues[0];
      this.patient_services[index].employee_type = dataValues[1];
      // this.patient_services[index].employee_type = this.refDoctorsAll.find(m => m.id === Number(this.patient_services[index].referring_doctor_id)).employee_type;
    }, 500);
  }

  getCountryData(): any {
    this.locationService.countries().subscribe(
      (data) => {
        this.countries = data.countries;
        this.employeeVisitForm.patchValue({
          state: '',
        });
      },
      (err) => {},
    );
  }

  updateCountryFun(value): any {
    if (this.countries.length > 0) {
      this.states = this.countries.filter(
        (t) => t.id === Number(value),
      )[0].states;
      this.cities = [];
      this.employeeVisitForm.patchValue({
        state: '',
        city: '',
      });
    }
  }

  updateStateFun(value): any {
    if (this.states.length > 0) {
      this.cities = this.states.filter((t) => t.id === Number(value))[0].cities;
      this.employeeVisitForm.patchValue({
        city: '',
      });
    }
  }

  getEmployeesDoctorWise(): any {
    this.allEmployeesStaffTypeDoctor = [];
    this.financeConfigService.getAllActiveEmployees().subscribe(
      (data) => {
        this.allEmployeesStaffTypeDoctor = data;
        setTimeout(() => {
          $('#employees').selectpicker();
          $('#employees').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  commentfield(index): any {
    this.patient_services[index].comments = '';
    this.patient_services[index].commentField =
      !this.patient_services[index].commentField;
  }

  getFrontDeskControlData(): any {
    this.ngxLoader.start();
    this.systemControlsService.getFrontDeskControlData(this.requiredFields).subscribe(
      (data) => {
        const allowDupServiceTypes =
          data.system_front_desk_control?.allow_dup_service_types;
        if (allowDupServiceTypes) {
          this.blockedDuplicateServices = Object.entries(allowDupServiceTypes)
            .filter(([key, value]) => value === 'false')
            .map(([key]) => key);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  updateRoomNumber(): any {
    $('#updateRoomNumberModal').modal('show');
    this.roomNumberUpdated = this.doctorRoomNumber;
  }

  saveDoctorRoomNumber(): any {
    this.isUpdateRoomNumber = true;
    this.ngxLoader.start();
    this.doctorConfigService
      .updateRoomNumber(this.doctor_id, this.roomNumberUpdated)
      .subscribe(
        (data) => {
          this.isUpdateRoomNumber = false;
          this.doctorRoomNumber = data.user.room_number;
          $('#updateRoomNumberModal').modal('hide');
          const doctorId = parseInt(this.doctor_id);

          this.doctors.forEach((doctorGroup) => {
            doctorGroup.forEach((doctor) => {
              if (doctorId === doctor.id) {
                doctor.room_number = this.doctorRoomNumber;
                return;
              }
            });
          });
          this.ngxLoader.stop();
          toastr.success('Room Number Updated');
        },
        (error) => {
          this.isUpdateRoomNumber = false;
          this.ngxLoader.stop();
        },
      );
  }
  onlyNumeric(event: any): any {
    const pattern = /^[1-9][0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (event.charCode !== 0) {
      const newValue = event.target.value + inputChar;
      if (!pattern.test(newValue)) {
        event.preventDefault();
      }
    }
  }
  onlyNumbers(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    const currentInput = event.target.value;
    if (!pattern.test(inputChar) || currentInput.length >= 10) {
      event.preventDefault();
    }
  }
  positiveNoWithDecimal(e: any, decimal: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    const value = String(e.target.value);
    const index = value.indexOf('.');
    const val = value.split('.');
    if (inputChar === '.' && value.includes('.')) {
      event.preventDefault();
      return false;
    }
    if (inputChar === '.') {
      return true;
    }
    if (index >= 0 && val.length > 1) {
      if (val[1].length >= decimal - 1) {
        event.preventDefault();
        return false;
      }
    }
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
  onlyNumericWithDecimal(event: any): any {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }
  showRegistrationsCard(): any{
    this.showRegistrationCard = !this.showRegistrationCard;
  }
  onChangeServicePaymentMode(): any {
    if (this.bankList.length) {
      setTimeout((e) => {
        $('.selectPickerBankDetail').selectpicker('refresh');
        $('.selectPickerBankDetail').selectpicker();
      }, 500);
    }
  }
  getBankDetail(): any {
    this.loan.getFinanceBankList().subscribe(
      (data) => {
        this.bankList = data;
        if (this.bankList.length) {
          setTimeout((e) => {
            $('.selectPickerBankDetail').selectpicker('refresh');
            $('.selectPickerBankDetail').selectpicker();
          }, 500);
        }
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  // System Admin Controls
  getBillingInvoiceControls(): any {
    this.userService.getInvoiceControls().subscribe(
      (data) => {
        if (data.system_billing_control) {
          this.restrictPaidInvoiceForIPD =
            data?.system_billing_control?.invoice_controls?.restrict_paid_invoice_for_ipd;
          if (this.isAdmitted && this.restrictPaidInvoiceForIPD){
            this.paymentStatus = 'unpaid';
            this.serviceForm.mode_of_payment = 'Credit';
          }
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
