<!--                  previous history area-->
<div class="card mb-3">
  <div class="card-body service-card p-5">
    <div class="row">
      <div class="col-6">
        <h4 class="mx-0 mb-3">Previous Services</h4>
      </div>
      <div class="col-3">
        <div class="d-flex justify-content-end">
          <span class="mt-2 mr-2">Paid</span>
          <span class="switch switch-icon">
            <label>
              <input
                type="checkbox"
                #paymentTypes
                name="select"
                [(ngModel)]="paymentTypeStatus"
                (click)="
                  fetchServicesHistory(visitTypes.checked, paymentTypes.checked)
                "
              />
              <span></span>
            </label>
          </span>
          <span class="mt-2 ml-1">Unpaid</span>
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex justify-content-end">
          <span class="mt-2 mr-2">IPD</span>
          <span class="switch switch-icon">
            <label>
              <input
                type="checkbox"
                #visitTypes
                name="select"
                [(ngModel)]="visitTypeStatus"
                (click)="
                  fetchServicesHistory(visitTypes.checked, paymentTypes.checked)
                "
              />
              <span></span>
            </label>
          </span>
          <span class="mt-2 ml-1">OPD</span>
        </div>
      </div>
    </div>

    <div class="" id="grandparentContent" aria-expanded="true">
      <table class="table table-hover m-0">
        <thead>
          <tr>
            <th style="width: 6%"></th>
            <th style="width: 5%">
              <input
                type="checkbox"
                [checked]="all_invoices_checked"
                class="margin-right-5 cursor-pointer invoiceCheckbox"
                (click)="select_all_invoices($event)"
              />
            </th>
            <th style="width: 5%"></th>
            <th style="width: 10%"></th>
            <th style="width: 6%">Base Price</th>
            <th style="width: 5%">Qty</th>
            <th style="width: 8%">System Discount</th>
            <th style="width: 8%">Manual Discount</th>
            <th style="width: 7%">Total Discount</th>
            <th style="width: 7%">Net Amount</th>
            <th style="width: 6%">Cash</th>
            <th style="width: 8%">Receivable</th>
            <th style="width: 6%">Status</th>
            <th style="width: 13%">Doctor</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let invoice of patientServiceHistory; let i = index"
          >
            <!--                            <ng-container *ngIf="invoice.admissioplun_id === null">-->

            <tr class="mainRow">
              <th class="icon-class" style="width: 6%">
                <button
                  class="btn btn-sm btn-secondary toggle-btn-rotate"
                  data-toggle="collapse"
                  [attr.data-target]="'.parentContent' + i"
                >
                  <i class="fas fa-angle-down p-0"></i>
                </button>
              </th>
              <th style="width: 5%">
                <!--                                  <input type="checkbox" *ngIf="invoice.payment_status === 'unpaid'" class="margin-right-5 invoiceCheckbox" [checked]="selctedInvoceIds.indexOf(invoice.id) > -1 " (click)="$event.stopPropagation();selectInvoice(invoice, $event)">-->
                <input
                  type="checkbox"
                  *ngIf="invoice.payment_status === 'unpaid'"
                  class="margin-right-5 invoiceCheckbox"
                  [(ngModel)]="invoice.selected"
                  (click)="$event.stopPropagation(); selectInvoice()"
                />
              </th>
              <th style="width: 5%"></th>
              <th style="width: 10%">
                <div class="dropdown dropdown-inline">
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm ml-2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="padding: 2px 5px"
                  >
                    Print
                  </button>
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item cursor-pointer"
                      (click)="
                        printThermalReceipt(invoice.id, invoice.visit_id)
                      "
                      >Thermal Receipt</a
                    >
                    <a
                      class="dropdown-item cursor-pointer"
                      (click)="
                        printFullInvoice(
                          invoice.id,
                          invoice.visit_id,
                          invoice?.patient?.mrn
                        )
                      "
                      >Detailed Bill</a
                    >
                    <a
                      *ngIf="invoice.visit_id"
                      class="dropdown-item cursor-pointer"
                      (click)="
                        visitWiseInvoice(
                          invoice.id,
                          invoice.visit_id,
                          invoice?.patient?.mrn
                        )
                      "
                    >Visit wise invoices</a
                    >
                  </div>
                </div>
              </th>
              <th style="width: 6%">{{ invoice.base_price | number }}</th>
              <th style="width: 5%">-</th>
              <th style="width: 8%">{{ invoice?.discount | number }}</th>
              <th style="width: 8%">
                {{ invoice?.approved_manual_discount | number }}
              </th>
              <th style="width: 7%">
                {{ invoice?.approved_discount | number }}
              </th>
              <th style="width: 7%">
                {{ invoice.base_price - invoice.approved_discount | number }}
              </th>
              <th style="width: 6%">{{ invoice.cash | number }}</th>
              <th style="width: 8%">{{ invoice.receivable | number }}</th>
              <th style="width: 6%">
                {{ invoice.payment_status | titlecase }}
              </th>
              <th style="width: 13%"></th>
            </tr>

            <tr
              class="collapse parentContent{{ i }} childRow"
              *ngFor="let service of invoice?.all_items; let j = index"
            >
              <td colspan="3">{{ service?.service?.name }}</td>
              <td>
                <a
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                  (click)="printInvoiceCancelItem(service.id)"
                  *ngIf="service.cancelled === true"
                >
                  <span class="svg-icon svg-icon-primary svg-icon-2x"
                    ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                          fill="#000000"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="9"
                          width="7"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="9"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="13"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="13"
                          width="7"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="17"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="17"
                          width="7"
                          height="2"
                          rx="1"
                        />
                      </g></svg
                    ><!--end::Svg Icon--></span
                  >
                </a>
                <span
                  class="label label-light-primary label-inline py-4 cursor-pointer"
                  (click)="printDialysisService(service.dialysis_id)"
                  *ngIf="
                    service?.service_type === 'DialysisService' &&
                    service?.dialysis_status === 'completed'
                  "
                  >Flow Sheet</span
                >
                <a
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3 cursor-pointer"
                  (click)="openPrescriptionSlip(invoice.visit_id)"
                  *ngIf="service.is_doctor_fee_service === true"
                >
                  <span class="svg-icon svg-icon-primary svg-icon-2x"
                    ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Communication\Clipboard-list.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                          fill="#000000"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="9"
                          width="7"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="9"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="13"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="13"
                          width="7"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="17"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="17"
                          width="7"
                          height="2"
                          rx="1"
                        />
                      </g></svg
                    ><!--end::Svg Icon--></span
                  >
                </a>
              </td>
              <td>{{ service.base_price }}</td>
              <td>{{ service.quantity }}</td>
              <td>{{ service.discount }}</td>
              <td>{{ service.approved_manual_discount }}</td>
              <td>{{ service.approved_discount }}</td>
              <td>
                {{
                  service.base_price * service.quantity -
                    service?.approved_discount
                }}
              </td>
              <td colspan="2">
                <span class="text-muted font-weight-bold d-block mb-2">
                  <span
                    class="label label-lg label-light-dark label-inline cursor-pointer w-100 h-100 justify-content-start py-1 px-2 truncate-text"
                    (click)="changePerformingType(service)"
                    >Per:
                    {{
                      service?.performing_doctor_name || "Not Assigned"
                    }}</span
                  >
                </span>
              </td>
              <td colspan="2">
                <span class="text-muted font-weight-bold d-block">
                  <span
                    class="label label-lg label-light-dark label-inline cursor-pointer w-100 h-100 justify-content-start py-1 px-2 truncate-text"
                    (click)="changeReferringType(service)"
                    >Ref:
                    {{ service?.referring_doctor_name || "Not Assigned" }}</span
                  >
                </span>
              </td>
            </tr>

            <!--                            </ng-container>-->
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--   previous history area calculation-->
<div class="card">
  <div class="card-body service-card p-5">
    <div class="row">
      <div class="col-md-8 text-left">
        <div class="row m-0">
          <div class="col-3" *ngIf="taxesData.length">
            <ng-container *ngFor="let tax of taxesData">
              <div class="d-flex justify-content-between align-items-center">
                <!-- Tax Name -->
                <div>{{ tax?.name }} <span *ngIf="tax?.value && tax?.value_type === 'Percentage'">({{ tax?.value_type === 'Percentage' ? tax?.value + '%' : ''}})</span></div>
                <!-- Tax Value -->
                <div class="ml-auto text-center">
                  {{tax?.totalTax || 0 }}
                </div>
              </div>
            </ng-container>

            <!-- Total Tax -->
            <div class="d-flex justify-content-between mt-3 font-weight-bold">
              <label>Total Tax</label>
              <span class="ml-auto text-right">
                                  {{ totalTaxValue || 0 | number: "1.0-0" }}
                                </span>
            </div>
          </div>
          <div class="col-2">
            <label>Cash</label>
            <!--                                (keyup)="updateServiceReceivableOfInvoices()"-->
            <!--                                (keypress)="updateServiceReceivableOfInvoices()"-->
            <input
              class="form-control form-control-sm"
              type="number"
              (keypress)="_onlyNumeric($event)"
              style="max-width: 130px"
              [disabled]="panelType === true"
              [(ngModel)]="servicePaidForm.cash"
              (change)="updateServiceReceivableOfInvoices()"
              (keyup)="updateServiceReceivableOfInvoices()"
            />
          </div>

          <div class="col-2">
            <label>Receivable %</label>
            <input
              class="form-control form-control-sm"
              disabled
              style="max-width: 130px"
              [(ngModel)]="servicePaidForm.receivable_percentage"
            />
          </div>
          <div class="col-3">
            <label>Receivable Amount:</label>
            <input
              class="form-control form-control-sm"
              disabled
              style="max-width: 130px"
              [(ngModel)]="servicePaidForm.receivable"
            />
          </div>

          <div class="col-2">
            <label>Ledger</label>
            <select
              class="form-control form-control-sm"
              style="max-width: 130px"
              id="consultantLedger"
              [disabled]="servicePaidForm.receivable < 1 || panelType === true"
              [(ngModel)]="servicePaidForm.ledger"
            >
              <option value="" [disabled]="true">Select</option>
              <ng-container *ngFor="let account of ledgersList">
                <option
                  value="{{ account.id }}"
                  *ngIf="account.config_type === 'ledger'"
                >
                  {{ account.name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <span *ngIf="panelType === false">
          <div class="padding-0 col-xs-12 font-size-11 text-left">
            <input
              (click)="paymentMethodForInvoices('Cash', $event)"
              [checked]="servicePaidForm.mode_of_payment === 'Cash'"
              class="mr-3 invoiceCheckbox"
              type="checkbox"
            />Cash
          </div>
          <div class="padding-0 col-xs-12 font-size-11 text-left">
            <input
              (click)="paymentMethodForInvoices('Cheque', $event)"
              [checked]="servicePaidForm.mode_of_payment === 'Cheque'"
              class="mr-3 invoiceCheckbox"
              type="checkbox"
            />Cheque
          </div>
          <div class="padding-0 col-xs-12 font-size-11 text-left">
            <input
              (click)="paymentMethodForInvoices('Credit Card', $event)"
              [checked]="servicePaidForm.mode_of_payment === 'Credit Card'"
              class="mr-3 invoiceCheckbox"
              type="checkbox"
            />Credit Card
            <input
              *ngIf="servicePaidForm.mode_of_payment == 'Credit Card'"
              [(ngModel)]="servicePaidForm.payment_details"
              class="form-control"
              placeholder="payment detail"
              type="text"
            />
          </div>
        </span>
        <span *ngIf="panelType === true">
          <div class="padding-0 col-xs-12 font-size-11 text-left">
            <input
              (click)="paymentMethodForInvoices('Credit', $event)"
              [checked]="servicePaidForm.mode_of_payment === 'Credit'"
              class="mr-3 invoiceCheckbox"
              type="checkbox"
            />Credit
          </div>
        </span>
      </div>

      <div class="col-md-2 text-right">
        <button *ngIf="isBackButton"
          (click)="backRoute()"
          class="btn btn-primary margin-left-15"
          id="service-btn"
          type="button"
        >
          Back
        </button>
        <button
          (click)="unpaidToPaidServicesConfirm()"
          class="btn btn-primary pull-right ml-5"
          id="proceed-btn"
          type="submit"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #serviceHistoryUpdateConfirm let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <!--            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" >-->
            Are you sure you want to update the services!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="unpaidToPaidServices()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
