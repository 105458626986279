import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {OpdService} from '../../../services/opd.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {HrService} from "../../../services/hr.service";
import {DepartmentsbyhospitalService} from "../../../services/departmentsbyhospital.service";

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-opd-template-modal',
  templateUrl: './opd-template-modal.component.html',
  styleUrls: ['./opd-template-modal.component.css']
})
export class OpdTemplateModalComponent implements OnInit {
  @Output() getOpdTemplateData: EventEmitter<string[]> = new EventEmitter();
  @Input() type = '';
  prescriptionForm: UntypedFormGroup;

  templateName = '';
  self = true;
  description = '';
  opdTemplateData = [];
  isSubmit = false;
  indexData: any;
  isAdd = false;
  presId: any;
  disableBtn = false;
  employees = [];
  depart = [];

  constructor(private ngxLoader: NgxUiLoaderService,
              private opdService: OpdService,
              private fb: UntypedFormBuilder,
              private departmentsByHospital: DepartmentsbyhospitalService,
              private hrService: HrService
            ) {
    this.prescriptionForm = this.fb.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      type: ['', Validators.required],
      employee: ['', Validators.required],
      department: ['', Validators.required],
      description: [''],
      status: [true],
    });
  }


  // Listing
  getOpdTemplateListing(): any{
    this.opdService.opdTemplateListing(this.type).subscribe(data => {
      this.opdTemplateData = data.opd_configurators;
    }, err => {
      toastr.error(err.error.message);
    });
  }
  stripHtml(content: string): string {
    return content.replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
      .replace(/&nbsp;/g, '') // Remove non-breaking spaces
      .trim();
  }
  submit(): any{
    const summernoteContent = $('#description').summernote('code'); // Get Summernote content
    if (this.templateName === '' || this.templateName.trim() === ''){
      toastr.error('Please add Template Name');
      return;
    } else if (!summernoteContent || this.stripHtml(summernoteContent) === '') {
      toastr.error('Please add Description');
      return;
    }
    const obj = {
      name: this.templateName,
      description: $('#description').summernote('isEmpty') === false ? $('#description').summernote('code') : '',
      self: this.self,
      type: this.type,
    };
    this.isSubmit = true;
    this.ngxLoader.start();
    this.opdService.addOpdTemplate(obj).subscribe(data => {
      this.ngxLoader.stop();
      this.isSubmit = false;
      this.getOpdTemplateListing();
      this.getOpdTemplateData.emit();
      this.templateName = '';
      $('#description').summernote('code', '');
      this.description = '';
      this.self = true;
    }, err => {
      this.ngxLoader.stop();
      this.isSubmit = false;
      toastr.error(err.error.message);
    });
  }

  getDepartments(): any {
    this.departmentsByHospital.indexV2().subscribe((data) => {
      this.depart = data.departments;
      setTimeout((e) => {
        $('.selectPickerDepartment').selectpicker('refresh');
        $('.selectPickerDepartment').selectpicker();
      }, 500);
    });
  }
  ngOnInit(): void {
    $('#description').summernote({
      height: 100,
      // placeholder: 'Drug History',
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        // ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
      ],
    });
    $('#description').summernote('code', '');
    this.getOpdTemplateListing();
    this.getDepartments();
    this.getEmployees();
  }

  maxLength(event: KeyboardEvent): any{
    const inputElement = event.target as HTMLInputElement;

    // Check if the length of the value is 50 or more
    if (inputElement.value.length >= 50) {
      event.preventDefault(); // Prevent further input
    }
  }
  getEmployees(): any {
    this.employees = [];
    setTimeout((e) => {
      $('#employee').selectpicker('refresh');
    }, 200);
    this.hrService.employeeListNew().subscribe(
      (data) => {
        this.employees = data;
        setTimeout((e) => {
          $('#employee').selectpicker('refresh');
          $('#employee').selectpicker();
        }, 500);
      },
      (err) => {
        setTimeout((e) => {
          $('#employee').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  edit(list): any{
    this.presId = '';
    $('#opdPresModal').modal('show');
    setTimeout(() => {
      $('#templateDescription').summernote({
        height: 100,
        // placeholder: 'Drug History',
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'underline', 'clear']],
          ['fontname', ['fontname']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link', 'picture', 'video']],
          ['view', ['fullscreen', 'codeview', 'help']],
        ],
      });
      this.presId = list.id;
    }, 200);
    setTimeout(() => {
      this.prescriptionForm.patchValue({
        code: list.code,
        name: list.name,
        type: list.type,
        employee: list.employee,
        department: list.department,
        description: '',
        status: list.status,
      });
      $('#templateDescription').summernote(
        'code',
        list?.description,
      );
    }, 500);
    setTimeout((e) => {
      $('.selectPickerDepartment').selectpicker('refresh');
      $('.selectPickerDepartment').selectpicker();
      $('#employee').selectpicker('refresh');
      $('#employee').selectpicker();
    }, 700);
  }
  // Submit Tag
  onsubmit(): any {
    this.disableBtn = true;
    this.isAdd = true;
    if (this.prescriptionForm.invalid){
      this.disableBtn = false;
      return;
    }
    if ($('#templateDescription').summernote('isEmpty') === false) {
      this.prescriptionForm
        .get('description')
        .setValue( $('#templateDescription').summernote('code'),
        );
    } else {
      this.prescriptionForm.get('description').setValue('');
    }
    this.ngxLoader.start();
    this.opdService
      .updatePrescriptionConfig(this.prescriptionForm.value, this.presId)
      .subscribe(
        (data) => {
          this.isAdd = false;
          this.disableBtn = false;
          this.ngxLoader.stop();
          this.getOpdTemplateListing();
          this.closeTemplateModal();
          toastr.success('Updated Successfully');
        },
        (err) => {
          this.disableBtn = false;
          this.ngxLoader.stop();
        },
      );
    }

  closeTemplateModal(): any{
    $('#opdPresModal').modal('hide');
    this.prescriptionForm.patchValue({
      code: '',
      name: '',
      type: '',
      employee: '',
      department: '',
      description: '',
      status: '',
    });
    $('#templateDescription').summernote(
      'code',
      '',
    );
  }
}
