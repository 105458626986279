<div style="display: flex; align-items: center; justify-content: left">
  <div class="card card-custom" style="width: 575px">
    <div class="card-body" style="padding: 15px">
      <div style="width: 100%">
        <!-- header table -->
        <table class="table">
          <tbody>
            <tr class="borders">
              <td style="width: 15%; border-top: 0 !important; padding: 0">
                <img
                  [src]="settingData?.logo?.url"
                  style="width: auto; height: 40px"
                />
              </td>
              <td style="width: 70%; border-top: 0 !important; padding: 0">
                <div class="text-center">
                  <h3>{{ settingData?.title }}</h3>
                </div>
              </td>
              <td style="width: 15%; border-top: 0 !important; padding: 0"></td>
            </tr>
          </tbody>
        </table>
        <hr class="my-3 line" />
      </div>
      <!--Table-->
      <div class="d-flex">
        <div style="width: 35%">
          <div><h4>Patient Details:</h4></div>
          <div>
            <img
              class="brCode"
              *ngIf="patients?.barcode_url"
              [src]="patients?.barcode_url"
            />
          </div>
          <div style="font-weight: bold; font-size: 16px">
            MRN: {{ patients?.mrn }}
          </div>
          <div style="font-weight: bold; font-size: 16px">
            {{ patients?.name }}
          </div>
          <div style="font-weight: bold">
            {{ patients?.age }}, {{ patients?.gender | titlecase }}
          </div>
          <div style="font-weight: bold">{{ patients?.patient_type }}</div>
          <!--              <td style="width: 15%; font-weight: bold; margin-left: 10px">Allergies</td>-->
          <div style="font-weight: bold" *ngIf="patients?.ward">
            {{ " " + patients.ward }}
          </div>
          <div style="font-weight: bold" *ngIf="patients?.bed">
            Bed/Room: ({{ patients?.bed }})
          </div>
        </div>
        <div style="border-right: 2px solid black; margin-left: 10px"></div>
        <div style="width: 65%; margin-left: 15px">
          <div><h4>Medicine Details:</h4></div>
          <ng-container *ngFor="let data of paitentMedicineHistory">
            <span style="font-weight: bold" *ngIf="data?.stoc_type"
              >{{ data?.stoc_type }}
            </span>
            <span style="font-weight: bold" *ngIf="data.name"
              >- {{ data.name }}
            </span>
            <span
              style="font-weight: bold"
              *ngIf="data?.dos_type || data?.dose_other_quantity"
              >- {{ data?.dos_type || data?.dose_other_quantity }}
            </span>
            <span style="font-weight: bold" *ngIf="data?.frequency"
              >- {{ data?.frequency }}
            </span>
            <span style="font-weight: bold" *ngIf="data?.routee"
              >- {{ data?.routee }}
            </span>
            <span style="font-weight: bold" *ngIf="data?.issued_quantity"
              >* {{ data?.issued_quantity || 0 }}
            </span>
            <!--              <td style="width: 15%; font-weight: bold; margin-left: 10px">Allergies</td>-->
            <div *ngIf="data?.advised_by">
              <span>Advised By: </span>
              <span>{{ data?.advised_by }}</span>
            </div>
            <div *ngIf="data?.comments">
              <span>Doctor Comments: </span>
              <span>{{ data?.comments }}</span>
            </div>
            <div *ngIf="data?.requested_by">
              <span>Requested By: </span>
              <span>{{ data?.requested_by }}</span>
            </div>
            <div *ngIf="data?.approved_by">
              <span>Approved By: </span>
              <span>{{ data?.approved_by }}</span>
            </div>
            <div *ngIf="data?.pharmacist_comments">
              <span>Pharmacist Comments: </span>
              <span>{{ data?.pharmacist_comments }}</span>
            </div>
            <div  *ngIf="data?.status === 'Issued' || data?.status === 'Partial Issued'">
              <span>Issuance Date: </span>
              <span>{{ data?.issued_on | dateTimeFormat }}</span>
            </div>
            <div *ngIf="data?.expiry">
              <span>Expiry Date: </span>
              <span>{{ data?.expiry }}</span>
            </div>
            <!--          <td style="width: 10%; font-weight: bold; margin-left: 10px">bar code:</td>-->
            <div
              style="
                border-bottom: 1px solid rgba(0, 0, 0, 0.22);
                margin-top: 10px;
                margin-bottom: 10px;
              "
            ></div>
          </ng-container>
        </div>
      </div>
      <!--      <div style="border-bottom: 2px solid black"></div>-->
      <!--      <table>-->
      <!--        <tr class="mt-4">-->
      <!--          <td style="font-weight: bold">Panadol, </td>-->
      <!--          <td style="font-weight: bold">Before Breakfast, </td>-->
      <!--          <td style="font-weight: bold">Bed Time, </td>-->
      <!--          &lt;!&ndash;              <td style="width: 15%; font-weight: bold; margin-left: 10px">Allergies</td>&ndash;&gt;-->
      <!--          <td style="font-weight: bold">10 packets </td>-->
      <!--&lt;!&ndash;          <td style="width: 10%; font-weight: bold; margin-left: 10px">bar code:</td>&ndash;&gt;-->
      <!--        </tr>-->
      <!--      </table>-->
    </div>
  </div>
</div>
