<div class="">
  <form [formGroup]="LabParameterForm" (ngSubmit)="newLabParameter()">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Title <span class="text-danger">*</span></label>
          <input
            type="text"
            formControlName="title"
            class="form-control form-control-sm"
            placeholder="Enter Parameter Name"
            [ngClass]="{
              'is-invalid': newParametersubmitted && f.title.errors
            }"
          />
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> UOM </label>
          <select
            class="form-control form-control-sm"
            formControlName="uom"
            data-live-search="true"
            id="uomParameter"
            [ngClass]="{ 'is-invalid': newParametersubmitted && f.uom.errors }"
          >
            <option value="" [selected]="true">Select uom</option>
            <option [value]="uom.description" *ngFor="let uom of labUOM">
              {{ uom.description }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="form-group">
          <label> Reference Value/comments </label>
          <textarea
            id="rangeComments"
            class="form-control form-control-sm"
            rows="1"
            placeholder="Comments"
          ></textarea>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Result format <span class="text-danger">*</span></label>
          <select
            class="form-control form-control-sm"
            id="RefValue"
            [ngClass]="{
              'is-invalid': newParametersubmitted && f.parameter_type.errors
            }"
            (change)="updateParameterType($event.target)"
          >
            <option value="" [selected]="true" [disabled]="true">
              Select Result Format
            </option>
            <option value="text">Text Filed</option>
            <option value="comboNumber">Number Field</option>
            <option value="comboDropdown">Dropdown</option>
            <option value="editor">Text Editor</option>
            <option value="single">Age and Gender wise</option>
          </select>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>Machine</label>
          <select
            class="form-control form-control-sm"
            formControlName="machine_id"
          >
            <option value="">Select</option>
            <ng-container *ngFor="let type of lisMachine">
              <option [value]="type.id">{{ type.description }}</option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Machine Code </label>
          <input
            type="text"
            formControlName="machine_code"
            class="form-control form-control-sm"
            placeholder="Enter Machine Code"
          />
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12 align-self-center">
        <div class="form-group">
          <label class="checkbox checkbox-sm">
            <input
              type="checkbox"
              formControlName="is_active"
              class=""
              placeholder="Enter status"
              [ngClass]="{
                'is-invalid': newParametersubmitted && f.is_active.errors
              }"
            />
            <span class="mr-2"></span>Status
          </label>
        </div>
      </div>

      <div
        class="col-lg-3 col-md-3 col-sm-6 col-12"
        *ngIf="
          LabParameterForm.get('parameter_type').value === 'combo' ||
          LabParameterForm.get('parameter_type').value === 'text'
        "
      >
        <div class="form-group">
          <label class="mt-2 ml-4">
            <span>Cut-off & Patient Value?</span>
          </label>
          <span class="switch switch-icon">
            <label class="ml-2">
              <input
                type="checkbox"
                formControlName="show_cutoff_patient_value"
                [(ngModel)]="showCutoffAndPatientValue"
              />
              <span></span>
            </label>
          </span>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-3 col-sm-6 col-12"
        *ngIf="showCutoffAndPatientValue"
      >
        <div class="form-group">
          <label> Cutoff Value </label>
          <input
            type="text"
            formControlName="cutoff_value"
            class="form-control form-control-sm"
          />
        </div>
      </div>
      <div
        class="col-lg-3 col-md-3 col-sm-6 col-12"
        *ngIf="showCutoffAndPatientValue"
      >
        <div class="form-group">
          <label> Patient Value </label>
          <input
            type="text"
            formControlName="patient_value"
            class="form-control form-control-sm"
          />
        </div>
      </div>
    </div>
  </form>

  <!--  text lab start-->
  <div
    class="mb-4"
    *ngIf="LabParameterForm.get('parameter_type').value === 'text'"
  >
    <div class="  ">
      <h5 class="modal-title">Result Format</h5>
      <form [formGroup]="textReport">
        <div class="row">
          <!--              <div class="col-2">-->
          <!--                <label>Code</label>-->
          <!--                <input type="text" disabled formControlName="parameter_code" class="form-control form-control-sm" placeholder="Code" />-->
          <!--              </div>-->
          <div class="col-lg-3 col-md-3 col-sm-6 col-12">
            <!--              <textarea class="form-control" rows="10" formControlName="heading" [ngClass]="{ 'is-invalid': textReportsubmitted && testRep.heading.errors }"></textarea>-->
            <input
              type="text"
              formControlName="heading"
              class="form-control form-control-sm"
              placeholder="Enter report text"
              [ngClass]="{
                'is-invalid': textReportsubmitted && testRep.heading.errors
              }"
            />
          </div>
        </div>
      </form>
    </div>
  </div>

  <div
    class="mb-4"
    *ngIf="LabParameterForm.get('parameter_type').value === 'editor'"
  >
    <div class="  ">
      <h3>Result Format</h3>

      <form [formGroup]="editorTextReport">
        <div class="row">
          <!--              <div class="col-2">-->
          <!--                <label>Code</label>-->
          <!--                <input type="text" disabled formControlName="parameter_code" class="form-control form-control-sm" placeholder="Code" />-->
          <!--              </div>-->
          <div class="col-12">
            <!--                <label>Text</label>-->
            <textarea
              class="form-control"
              rows="5"
              placeholder="Enter report text"
              formControlName="heading"
              [ngClass]="{
                'is-invalid': textReportsubmitted && testRep.heading.errors
              }"
            ></textarea>
            <!--            <input-->
            <!--              type="text"-->
            <!--              formControlName="heading"-->
            <!--              class="form-control form-control-sm"-->
            <!--              placeholder="Enter report text"-->
            <!--              [ngClass]="{-->
            <!--                'is-invalid': textReportsubmitted && testRep.heading.errors-->
            <!--              }"-->
            <!--            />-->
          </div>
        </div>
      </form>
    </div>
  </div>
  <!--  text lab end-->

  <!--  decimal/single lab start-->
  <div
    *ngIf="LabParameterForm.get('parameter_type').value === 'single'"
    class="mb-4"
  >
    <div class="  ">
      <h3>Result Format</h3>

      <form [formGroup]="accessParameterForm">
        <table
          class="table table-vertical-center table-head-custom single-table"
        >
          <thead>
            <tr>
              <td colspan="4"></td>
              <td colspan="3" class="text-center">Normal Range</td>
              <td colspan="3" class="text-center">Critical Range</td>
              <td colspan="2"></td>
            </tr>
            <tr>
              <!--                <th>Code</th>-->
              <th>Gender</th>
              <th>Age type</th>
              <th>Start Age</th>
              <th>End Age</th>
              <th style="background-color: #d2c1a9a8;color: #3F4254!important;">Range Type</th>
              <th style="background-color: #d2c1a9a8;color: #3F4254!important;">Start Range</th>
              <th style="background-color: #d2c1a9a8;color: #3F4254!important;">End Range</th>
              <th style="background-color: #eb8c96c4;color: #3F4254!important;">Range Type</th>
              <th style="background-color: #eb8c96c4;color: #3F4254!important;">Start Range</th>
              <th style="background-color: #eb8c96c4;color: #3F4254!important;">End Range</th>
              <!--                <th>Heading</th>-->
              <th style="text-align: center">Active</th>
              <th style="text-align: center">
                <button
                  type="button"
                  (click)="addAccessRange()"
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                >
                  <span class="svg-icon svg-icon-primary svg-icon-2x"
                    ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                        <path
                          d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                          fill="#000000"
                        />
                      </g></svg
                    ><!--end::Svg Icon-->
                  </span>
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              formArrayName="accessRanges"
              *ngFor="let g of accessRangesArray.controls; let i = index"
            >
              <ng-container [formGroupName]="i">
                <!--                  <td><input type="text" disabled formControlName="parameter_code" value="" class="form-control form-control-sm" placeholder="Code" /></td>-->
                <!--                  <td>-->
                <td>
                  <select
                    class="form-control form-control-sm"
                    formControlName="gender"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.gender.invalid
                    }"
                  >
                    <!--                    <option value="" [disabled]="true">Gender</option>-->
                    <option [ngValue]="gender" *ngFor="let gender of genders">
                      {{ gender }}
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    class="form-control form-control-sm"
                    formControlName="age_type"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.age_type.invalid
                    }"
                  >
                    <option value="" [disabled]="true">Age in</option>
                    <option
                      [ngValue]="age_type"
                      *ngFor="let age_type of age_types"
                    >
                      {{ age_type }}
                    </option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    formControlName="age_start"
                    class="form-control form-control-sm"
                    min="0"
                    max="120"
                    placeholder="Age start"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.age_start.errors
                    }"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    formControlName="age_end"
                    min="0"
                    max="120"
                    class="form-control form-control-sm"
                    placeholder="Age end"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.age_end.errors
                    }"
                  />
                </td>
                <td>
                  <select
                    class="form-control form-control-sm"
                    formControlName="range_type"
                    (change)="updateRangeType($event.target.value, i)"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.range_type.invalid
                    }"
                  >
                    <!--                    <option value="" [disabled]="true">Range in</option>-->
                    <option value="in_between">In Between</option>
                    <option value="less_then">Less Then</option>
                    <option value="greater_then">Greater Then</option>
                    <option value="equal">Equal</option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    formControlName="start_range"
                    class="form-control form-control-sm"
                    placeholder="Start range"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.start_range.errors
                    }"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    formControlName="end_range"
                    class="form-control form-control-sm"
                    placeholder="End range"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.end_range.errors
                    }"
                  />
                </td>

                <td>
                  <select
                    class="form-control form-control-sm"
                    formControlName="critical_range_type"
                    (change)="updateRangeTypeForCritical($event.target.value, i)"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.critical_range_type.invalid
                    }"
                  >
                    <!--                    <option value="" [disabled]="true">Range in</option>-->
                    <option value="in_between">In Between</option>
                    <option value="less_then">Less Then</option>
                    <option value="greater_then">Greater Then</option>
                    <option value="equal">Equal</option>
                    <option value="< Start or > End">< Start or > End</option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    formControlName="critical_start_range"
                    class="form-control form-control-sm"
                    placeholder="Start range"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.critical_start_range.errors
                    }"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    formControlName="critical_end_range"
                    class="form-control form-control-sm"
                    placeholder="End range"
                    [ngClass]="{
                      'is-invalid': submitted && g.controls.critical_end_range.errors
                    }"
                  />
                </td>

                <!--                  <td><input type="text" formControlName="heading" class="form-control form-control-sm" placeholder="Heading" [ngClass]="{ 'is-invalid': submitted && g.controls.heading.errors }" /></td>-->
                <td style="text-align: center">
                  <label class="checkbox checkbox-sm justify-content-center">
                    <input type="checkbox" formControlName="status" />
                    <span></span>
                  </label>
                </td>
                <td style="text-align: center">
                  <button
                    type="button"
                    (click)="removeAccessRange(i)"
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x"
                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g></svg
                      ><!--end::Svg Icon--></span
                    >
                  </button>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
  <!--  decimal/single lab end-->

  <!--  combo lab start-->
  <div
    *ngIf="LabParameterForm.get('parameter_type').value === 'combo'"
    class="mb-4"
  >
    <h3>Result Format</h3>
    <div class="combo-form">
      <form [formGroup]="comboReport">
        <div class="row">
          <!--              <div class="col-2">-->
          <!--                <div class="form-group">-->
          <!--                  <label>Code</label>-->
          <!--                  <input type="text" disabled formControlName="parameter_code" value="" class="form-control form-control-sm" placeholder="Code" />-->
          <!--                </div>-->
          <!--              </div>-->
          <div class="col-12">
            <!--                <div class="form-group">-->
            <!--                  <label>Result type 1</label>-->
            <!--                  <select  class="form-control form-control-sm" formControlName="report_type_1" [ngClass]="{ 'is-invalid': comboReportsubmitted && comboRep.report_type_1.errors }" (change)="updateReportType1($event.target.value)">-->
            <!--                    <option value="" [disabled]="true">Select type </option>-->
            <!--                    <option value="text">Text</option>-->
            <!--                    <option value="decimal">Decimal</option>-->
            <!--                    <option value="dropdown">Dropdown</option>-->
            <!--                  </select>-->
            <!--                </div>-->

            <div class="form-group mt-4">
              <div *ngIf="comboReport.value.report_type_1 === 'dropdown'">
                <div class="row m-0">
                  <div class="col-2 pl-0 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Enter Dropdown Value"
                      name="addDropdownValue1"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="addDropdownValue1"
                      [ngClass]="{
                        'is-invalid':
                          comboReportsubmitted &&
                          comboRep.report_type_1_result.errors
                      }"
                    />
                  </div>
                  <div class="col-1 text-right pr-6">
                    <button
                      type="button"
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm combo-actions"
                      (click)="addComboValue1()"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                            <path
                              d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                              fill="#000000"
                            />
                          </g></svg
                        ><!--end::Svg Icon-->
                      </span>
                    </button>
                  </div>
                </div>
                <div
                  *ngFor="let value of addDropdownValue1Array; let i = index"
                  class="clearfix mt-2 pb-1"
                >
                  <div class="row m-0">
                    <div
                      class="col-3 p-0 align-self-center"
                      style="border-bottom: 1px solid #cccccc"
                    >
                      {{ value }}
                    </div>
                    <div class="col-3 text-left">
                      <a
                        (click)="removeComboValue1(i)"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm combo-actions"
                      >
                        <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <path
                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              />
                              <path
                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g></svg
                          ><!--end::Svg Icon--></span
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="comboReport.value.report_type_1 === 'text'">
                <input
                  type="text"
                  formControlName="report_type_1_result"
                  [ngClass]="{
                    'is-invalid':
                      comboReportsubmitted &&
                      comboRep.report_type_1_result.errors
                  }"
                  value=""
                  placeholder="Enter text"
                  class="form-control form-control-sm"
                />
              </div>

              <div *ngIf="comboReport.value.report_type_1 === 'decimal'">
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <input
                      type="number"
                      formControlName="report_type_1_result"
                      (keyup)="onlyNumeric($event)"
                      [ngClass]="{
                        'is-invalid':
                          comboReportsubmitted &&
                          comboRep.report_type_1_result.errors
                      }"
                      value=""
                      placeholder="Number field"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--              <div class="col-4">-->
          <!--&lt;!&ndash;                <div class="form-group">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <label>Result type 2</label>&ndash;&gt;-->
          <!--&lt;!&ndash;                  <select  class="form-control form-control-sm" formControlName="report_type_2" [ngClass]="{ 'is-invalid': comboReportsubmitted && comboRep.report_type_2.errors }" (change)="updateReportType2($event.target.value)">&ndash;&gt;-->
          <!--&lt;!&ndash;                    <option value="">Select type </option>&ndash;&gt;-->
          <!--&lt;!&ndash;                    <option value="text">Text</option>&ndash;&gt;-->
          <!--&lt;!&ndash;                    <option value="decimal">Decimal</option>&ndash;&gt;-->
          <!--&lt;!&ndash;                    <option value="dropdown">Dropdown</option>&ndash;&gt;-->
          <!--&lt;!&ndash;                  </select>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->

          <!--                <div class="form-group">-->

          <!--                  <div *ngIf="comboReport.value.report_type_2 === 'dropdown'">-->
          <!--                    <div class="row m-0">-->
          <!--                      <div class="col-8 p-0">-->
          <!--                        <input type="text" class="form-control form-control-sm"  value="" placeholder="Add value to dropdown" name="addDropdownValue2" [ngModelOptions]="{standalone: true}" [(ngModel)]="addDropdownValue2" [ngClass]="{ 'is-invalid': comboReportsubmitted && comboRep.report_type_2_result.errors }" />-->
          <!--                      </div>-->
          <!--                      <div class="col-4 p-0 text-right align-self-end">-->
          <!--                        <button type="button" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm combo-actions" (click)="addComboValue2()">-->
          <!--                          <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
          <!--                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
          <!--                              <rect x="0" y="0" width="24" height="24"/>-->
          <!--                            &lt;!&ndash;                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>&ndash;&gt;-->
          <!--                              <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000"/>-->
          <!--                          </g>-->
          <!--                      </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
          <!--                        </span>-->
          <!--                        </button>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div *ngFor="let value of addDropdownValue2Array; let i = index" class="clearfix mt-2 pb-1">-->
          <!--                      <div class="row m-0">-->
          <!--                        <div class="col-4 p-0 align-self-center" style="border-bottom: 1px solid #cccccc">-->
          <!--                          {{value}}-->
          <!--                        </div>-->
          <!--                        <div class="col-4 p-0 text-left">-->
          <!--                          <a class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm combo-actions" (click)="removeComboValue2(i)">-->
          <!--                            <span class="svg-icon svg-icon-primary svg-icon-2x">&lt;!&ndash;begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg&ndash;&gt;<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
          <!--                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
          <!--                                  <rect x="0" y="0" width="24" height="24"/>-->
          <!--                                  <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"/>-->
          <!--                                  <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>-->
          <!--                              </g>-->
          <!--                          </svg>&lt;!&ndash;end::Svg Icon&ndash;&gt;</span>-->
          <!--                          </a>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->

          <!--                  <div *ngIf="comboReport.value.report_type_2 === 'text'">-->
          <!--                    <input type="text" formControlName="report_type_2_result" value="" [ngClass]="{ 'is-invalid': comboReportsubmitted && comboRep.report_type_2_result.errors }" placeholder="Enter text" class="form-control form-control-sm" />-->
          <!--                  </div>-->

          <!--                  <div *ngIf="comboReport.value.report_type_2 === 'decimal'">-->
          <!--                    <input type="number" formControlName="report_type_2_result" value="" (keyup)="onlyNumeric($event)" [ngClass]="{ 'is-invalid': comboReportsubmitted && comboRep.report_type_2_result.errors }"  placeholder="Number field" class="form-control form-control-sm" />-->
          <!--                  </div>-->

          <!--                </div>-->

          <!--              </div>-->
        </div>
      </form>
    </div>
  </div>
  <!--  combo lab end-->
</div>

<div class="modal-footer mt-5">
  <button
    type="button"
    class="btn btn-secondary btn-hover-primary btn-sm mr-2"
    data-dismiss="modal"
  >
    Close
  </button>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    *ngIf="addPerameter"
    (click)="clearPerameterValues()"
  >
    Clear
  </button>
  <button
    type="button"
    *ngIf="!addPerameter"
    (click)="verify()"
    class="btn btn-primary btn-sm ml-2 mr-5"
  >
    Update
  </button>
  <button
    type="button"
    *ngIf="addPerameter"
    (click)="newLabParameter()"
    class="btn btn-primary btn-sm ml-2 mr-5"
  >
    Save
  </button>
</div>

<div
  class="modal fade"
  id="Confirm"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  data-backdrop="static"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md modal_width">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Select the Option</h5>
        <button
          type="button"
          class="close"
          (click)="closeConfirmModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-9">
          <span class="switch switch-icon mr-5">
            <span class="col-md-8">Create New Parameter</span>
            <label>
              <input
                type="checkbox"
                checked="checked"
                name="createNewParameter"
                [(ngModel)]="createNewParameter"
                (change)="changeCreateToggle()"
              />
              <span></span>
            </label>
          </span>
        </div>

        <div class="col-9">
          <span class="switch switch-icon mr-5">
            <span class="col-md-8">Update Orginal Parameter</span>
            <label>
              <input
                type="checkbox"
                checked="checked"
                name="updateNewParameter"
                (change)="changeUpdateToggle()"
                [(ngModel)]="updateNewParameter"
              />
              <span></span>
            </label>
          </span>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-hover-primary btn-sm mr-2"
          (click)="closeConfirmModal()"
        >
          Close
        </button>

        <button
          type="button"
          (click)="updatePerameterFun()"
          class="btn btn-primary btn-sm ml-2 mr-5"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>

<!--End::Content-->
