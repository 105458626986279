// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// For development env

export const environment = {
  production: false,
  rorUrl: 'https://testbe.ranecs.com/api',
  pythonUrl: 'https://testb.starzbytes.com',
  oldAdminUrl: 'https://testfe.ranecs.com',
  hospital: 'cloudSetting',
  pacsUrl: '',
  appVersion: '1.0.1',
};

// For Office ====> hippa-compliance
// export const environment = {
//   production: false,
//   rorUrl: '',
//   pythonUrl: 'http://157.245.244.21:89',
//   oldAdminUrl: '',
//   hospital: 'nationalHospital',
//   pacsUrl: '',
// };

// For Office ====> irvinetek-office
// export const environment = {
//   production: false,
//   rorUrl: 'http://testbe.ranecs.com:100/api',
//   pythonUrl: 'http://157.245.244.21:8080',
//   oldAdminUrl: '',
//   hospital: '',
//   pacsUrl: '',
// };

// For Office ====> irvinetek-staging-backup
// export const environment = {
//   production: false,
//   rorUrl: 'http://142.93.112.236/api',
//   pythonUrl: 'http://142.93.112.236:81',
//   oldAdminUrl: '',
//   hospital: '',
//   pacsUrl: '',
// };



// ==================== UOLTH SERVER ==========================
// uolth settings
// For Uolth Staging ====> uolth-staging
// ranecs-frontend-uol-new
// export const environment = {
//   production: false,
//   rorUrl: 'https://uolber.starzbytes.com/api',
//   pythonUrl: 'https://uolbe.ranecs.com',
//   oldAdminUrl: 'https://uolfe.ranecs.com',
//   hospital: 'uolHospital',
//   pacsUrl: 'RaidCustomProtocol:http://10.1.1.120:8080',
// };

// For Uolth local server ====> uolth-local-server
// export const environment = {
//   production: false,
//   rorUrl: 'http://10.1.1.126/api',
//   pythonUrl: 'http://10.1.1.126:81',
//   oldAdminUrl: 'http://10.1.1.124',
//   hospital: 'uolHospital',
//   pacsUrl: 'RaidCustomProtocol:http://10.1.1.120:8080',
// };



// For Uolth local backup server ====> uolth-local-backup-server
// export const environment = {
//   production: false,
//   rorUrl: 'http://10.3.1.144/api',
//   pythonUrl: 'http://10.3.1.144:81',
//   oldAdminUrl: 'http://10.3.1.147',
//   hospital: 'uolHospital',
//   pacsUrl: 'RaidCustomProtocol:http://10.3.1.143:8080',
// };



// For Uolth live server ====> uolth-live-server
// http://103.141.229.59:81
// export const environment = {
//   production: false,
//   rorUrl: 'http://103.141.229.58/api',
//   pythonUrl: 'http://103.141.229.58:81',
//   oldAdminUrl: 'http://103.141.229.59',
//   hospital: 'uolHospital',
//   pacsUrl: 'RaidCustomProtocol:http://10.1.1.120:8080',
// };



// ==================== NATIONAL SERVER ==========================
// For National Staging ====> nhmc-staging
// ranecs-frontend-national-new
// export const environment = {
//   production: false,
//   rorUrl: 'http://157.245.244.21:88/api',
//   pythonUrl: 'http://testbe.ranecs.com:96',
//   oldAdminUrl: 'http://testfe.ranecs.com:89',
//   hospital: 'uolHospital',
//   pacsUrl: 'http://192.168.0.203:8080',
// };

// For National Staging ====> nhmc-backup
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.1.241/api',
//   pythonUrl: 'http://192.168.1.241:81',
//   oldAdminUrl: '',
//   hospital: 'nationalHospital',
//   pacsUrl: 'http://192.168.0.203:8080',
// };


// For National Local ====> nhmc-local-server
// http://192.168.1.228:90
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.1.228/api',
//   pythonUrl: 'http://192.168.1.228:89',
//   oldAdminUrl: 'http://192.168.1.228:81',
//   hospital: 'nationalHospital',
//   pacsUrl: 'http://192.168.0.203:8080',
// };



// For National Live ====> nhmc-live-server
// http://110.39.5.195:91
// export const environment = {
//   production: false,
//   rorUrl: 'http://110.39.5.195/api',
//   pythonUrl: 'http://110.39.5.195:89',
//   oldAdminUrl: 'http://110.39.5.195:82',
//   hospital: 'nationalHospital',
//   pacsUrl: 'http://192.168.0.203:8080',
// };


// For National Live ====> nhmc-main-new
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.0.20/api',
//   pythonUrl: 'http://192.168.0.20:89',
//   oldAdminUrl: '',
//   hospital: 'nationalHospital',
//   pacsUrl: 'http://192.168.0.203:8080',
// };



// ==================== HAMEED LATIF HOSPITAL SERVER ==========================
// For Hameed Latif backup ====> hameed-staging
// export const environment = {
//   production: false,
//   rorUrl: 'http://161.35.54.230/api',
//   pythonUrl: 'http://161.35.54.230:89',
//   oldAdminUrl: '',
//   hospital: 'hameedLatif',
//   pacsUrl: '',
// };

// For Hameed Latif backup ====> hameed-backup
// export const environment = {
//   production: false,
//   rorUrl: 'http://172.16.0.156/api',
//   pythonUrl: 'http://172.16.0.156:81',
//   oldAdminUrl: '',
//   hospital: 'hameedLatif',
//   pacsUrl: '',
// };

// For Hameed Latif Local ====> hameed-local
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.4.105/api',
//   pythonUrl: 'http://192.168.4.105:81',
//   oldAdminUrl: 'http://192.168.4.156',
//   hospital: 'hameedLatif',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };



// For Hameed Latif Livee ====> hameed-live
// http://122.129.85.250:81/
// export const environment = {
//   production: false,
//   rorUrl: 'http://122.129.85.253/api',
//   pythonUrl: 'http://122.129.85.253:81',
//   oldAdminUrl: 'http://122.129.85.250',
//   hospital: 'hameedLatif',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };

// ==================== MEP SERVER ==========================

// For Hameed Latif Live ====> mep-live
// export const environment = {
//   production: false,
//   rorUrl: 'http://116.71.134.212:80/api',
//   pythonUrl: 'http://116.71.134.212:81',
//   oldAdminUrl: 'http://116.71.134.212:90',
//   hospital: 'mepServer',
//   pacsUrl: '',
// };


// ==================== UOL Dental Hospital SERVER ==========================

// For UOL Dental Live ====> uol-dental-live
//    10.1.0.5:81
// export const environment = {
//   production: false,
//   rorUrl: 'http://13.76.247.68/api',
//   pythonUrl: 'http://13.76.247.68:81',
//   oldAdminUrl: 'http://52.163.117.232',
//   hospital: 'dentalHospital',
//   pacsUrl: '',
// };

// ==================== Cloude ==========================
//   rorUrl: 'http://198.199.89.4:81/api',
//   pythonUrl: 'http://198.199.89.4:82',

// For cloude Live ====> mbmt-live
// export const environment = {
//   production: false,
//   rorUrl: 'https://ber.camdenhealthsys.com/api',
//   pythonUrl: 'https://bep.camdenhealthsys.com',
//   oldAdminUrl: '',
//   hospital: 'cloudSetting',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };


// For cloud Live ====> cloud-02
// export const environment = {
//   production: false,
//   rorUrl: 'https://ber.camdenhealthsys.com/api',
//   pythonUrl: 'https://bep2.camdenhealthsys.com',
//   oldAdminUrl: '',
//   hospital: 'cloudSetting',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };



// ==================== Camden Website ==========================
// For camden-web Live ====> camden-web
// export const environment = {
//   production: false,
//   rorUrl: 'http://bewr.camdenhealthsys.com/api',
//   pythonUrl: 'http://bewp.camdenhealthsys.com',
//   oldAdminUrl: '',
//   hospital: 'camdenWeb',
//   pacsUrl: '',
// };


// ==================== Farooq Hospital ==========================

//   rorUrl: 'http://122.129.84.237/api',
//   pythonUrl: 'http://122.129.84.237:81',

// ====> farooq-backup
// export const environment = {
//   production: false,
//   rorUrl: 'https://ber.fhdhareports.com/api',
//   pythonUrl: 'https://bep.fhdhareports.com',
//   oldAdminUrl: '',
//   hospital: 'farooqHospital',
//   pacsUrl: '',
// };

// ====> farooq-live
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.2.247/api',
//   pythonUrl: 'http://192.168.2.247:81',
//   oldAdminUrl: '',
//   hospital: 'farooqHospital',
//   pacsUrl: '',
//   appVersion: '1.0.0'
// };


// ==================== Dental Superior Hospital ==========================


// ====> dental-live
// export const environment = {
//   production: false,
//   rorUrl: 'https://dims.superior.edu.pk:3080/api',
//   pythonUrl: 'https://dims.superior.edu.pk:3081',
//   oldAdminUrl: '',
//   hospital: 'dentalHospital',
//   pacsUrl: '',
//   appVersion: '1.0.0'
// };

// // ====> dental-local branch
// export const environment = {
//   production: false,
//   rorUrl: 'https://dims.superior.edu.pk:80/api',
//   pythonUrl: 'https://dims.superior.edu.pk:81',
//   oldAdminUrl: '',
//   hospital: 'dentalHospital',
//   pacsUrl: '',
//   appVersion: '1.0.0'
// };

// ==================== JINNAH HOSPITAL PESHAWAR ==========================
// FRONTEND -> 192.168.4.5:82 ===> jinnah-peshawar
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.4.5:80/api',
//   pythonUrl: 'http://192.168.4.5:81',
//   oldAdminUrl: '',
//   hospital: 'jinnahHospitalPeshawar',
//   pacsUrl: '',
//   appVersion: '1.0.0'
// };

// FRONTEND -> 203.99.191.152:83 ===> jinnah-live-peshawar
// export const environment = {
//   production: false,
//   rorUrl: 'http://203.99.191.152:80/api',
//   pythonUrl: 'http://203.99.191.152:81',
//   oldAdminUrl: '',
//   hospital: 'jinnahHospitalPeshawar',
//   pacsUrl: '',
//   appVersion: '1.0.0'
// };

// FRONTEND -> 72.255.29.40:92 ===> jinnah-live-peshawar
// export const environment = {
//   production: false,
//   rorUrl: 'http://72.255.29.40:90/api',
//   pythonUrl: 'http://72.255.29.40:91',
//   oldAdminUrl: '',
//   hospital: 'jinnahHospitalPeshawar',
//   pacsUrl: '',
//   appVersion: '1.0.0'
// };

// ==================== CMA HOSPITAL ==========================
// FRONTEND -> https:/10.6.14.43:92 ===> cma-teaching-local-ip
// export const environment = {
//   production: false,
//   rorUrl: 'http://10.6.14.43:90/api',
//   pythonUrl: 'http://10.6.14.43:91',
//   oldAdminUrl: '',
//   hospital: 'cmaTeaching',
//   pacsUrl: '',
// };

// FRONTEND -> https://hmis.superior.edu.pk:82 ===> cma-teaching-local
// export const environment = {
//   production: false,
//   rorUrl: 'https://hmis.superior.edu.pk:80/api',
//   pythonUrl: 'https://hmis.superior.edu.pk:81',
//   oldAdminUrl: '',
//   hospital: 'cmaTeaching',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };

// FRONTEND -> https://hmis.superior.edu.pk:4084 ===> cma-teaching-live
// export const environment = {
//   production: false,
//   rorUrl: 'https://hmis.superior.edu.pk:4080/api',
//   pythonUrl: 'https://hmis.superior.edu.pk:4081',
//   oldAdminUrl: '',
//   hospital: 'cmaTeaching',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };


// ==================== CMA LAB SEHAT SAHULAT ==========================
// FRONTEND -> sims.superior.edu.pk:5582  ===> sehat-sahulat-live
// export const environment = {
//   production: false,
//   rorUrl: 'https://sims.superior.edu.pk:4080/api',
//   pythonUrl: 'https://sims.superior.edu.pk:4081',
//   oldAdminUrl: '',
//   hospital: 'cloudSetting',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };


// FRONTEND -> sims.superior.edu.pk:5582  ===> sehat-sahulat-local
// export const environment = {
//   production: false,
//   rorUrl: 'https://sims.superior.edu.pk:80/api',
//   pythonUrl: 'https://sims.superior.edu.pk:81',
//   oldAdminUrl: '',
//   hospital: 'cloudSetting',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };

// ==================== MASOOD HOSPITAL ==========================
// FRONTEND -> 192.168.10.251:82 ===> masood-hospital-local
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.10.251:80/api',
//   pythonUrl: 'http://192.168.10.251:81',
//   oldAdminUrl: '',
//   hospital: 'masoodHospital',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };

// FRONTEND ->  182.180.142.128:92 ===> masood-hospital-live
// export const environment = {
//   production: false,
//   rorUrl: 'http://182.180.142.128:90/api',
//   pythonUrl: 'http://182.180.142.128:91',
//   oldAdminUrl: '',
//   hospital: 'masoodHospital',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };


// ==================== Shahina Jamil Teaching Hospital ==========================
// FRONTEND -> sjth.camdenhealthsys.com ===> shahina-teach-live
// export const environment = {
//   production: false,
//   rorUrl: 'https://sjthr.camdenhealthsys.com/api',
//   pythonUrl: 'https://sjthp.camdenhealthsys.com',
//   oldAdminUrl: '',
//   hospital: 'cloudSetting',
//   pacsUrl: '',
// };


// FRONTEND -> 192.168.2.130:82 ===> shahina-teach-local
// export const environment = {
//   production: false,
//   rorUrl: 'http://192.168.2.130:80/api',
//   pythonUrl: 'http://192.168.2.130:81',
//   oldAdminUrl: '',
//   hospital: 'cloudSetting',
//   pacsUrl: '',
//   appVersion: '1.0.0',
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
